import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.trainerTransfer.fields.id'),
  },
  {
    name: 'trainerId',
    label: i18n(
      'entities.trainerTransfer.fields.trainerId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'externalId',
    label: i18n(
      'entities.trainerTransfer.fields.externalId',
    ),
  },
  {
    name: 'date',
    label: i18n('entities.trainerTransfer.fields.date'),
  },
  {
    name: 'originalAmount',
    label: i18n(
      'entities.trainerTransfer.fields.originalAmount',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'feesApplied',
    label: i18n(
      'entities.trainerTransfer.fields.feesApplied',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'discount',
    label: i18n('entities.trainerTransfer.fields.discount'),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'totalTransfered',
    label: i18n(
      'entities.trainerTransfer.fields.totalTransfered',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'description',
    label: i18n(
      'entities.trainerTransfer.fields.description',
    ),
  },
  {
    name: 'status',
    label: i18n('entities.trainerTransfer.fields.status'),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.trainerTransfer.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.trainerTransfer.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
