import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      adminTrainers: {
        id: 'adminTrainers',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      adminClients: {
        id: 'adminClients',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      userPreferenceImport: {
        id: 'userPreferenceImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userPreferenceCreate: {
        id: 'userPreferenceCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userPreferenceEdit: {
        id: 'userPreferenceEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userPreferenceDestroy: {
        id: 'userPreferenceDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userPreferenceRead: {
        id: 'userPreferenceRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userPreferenceAutocomplete: {
        id: 'userPreferenceAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      adminSettingsImport: {
        id: 'adminSettingsImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      adminSettingsCreate: {
        id: 'adminSettingsCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      adminSettingsEdit: {
        id: 'adminSettingsEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      adminSettingsDestroy: {
        id: 'adminSettingsDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      adminSettingsRead: {
        id: 'adminSettingsRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      adminSettingsAutocomplete: {
        id: 'adminSettingsAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      clientSettingsImport: {
        id: 'clientSettingsImport',
        allowedRoles: [roles.admin, roles.client],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      clientSettingsCreate: {
        id: 'clientSettingsCreate',
        allowedRoles: [roles.admin, roles.client],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientSettingsEdit: {
        id: 'clientSettingsEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientSettingsDestroy: {
        id: 'clientSettingsDestroy',
        allowedRoles: [roles.admin, roles.client],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientSettingsRead: {
        id: 'clientSettingsRead',
        allowedRoles: [roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      clientSettingsClient: {
        id: 'clientSettingsClient',
        allowedRoles: [roles.client],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      clientSettingsAutocomplete: {
        id: 'clientSettingsAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.client,
          roles.trainer,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      trainerTransferImport: {
        id: 'trainerTransferImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerTransferCreate: {
        id: 'trainerTransferCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerTransferEdit: {
        id: 'trainerTransferEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerTransferDestroy: {
        id: 'trainerTransferDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerTransferRead: {
        id: 'trainerTransferRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerTransferAutocomplete: {
        id: 'trainerTransferAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      subscriptionPlanImport: {
        id: 'subscriptionPlanImport',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      subscriptionPlanCreate: {
        id: 'subscriptionPlanCreate',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      subscriptionPlanEdit: {
        id: 'subscriptionPlanEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      subscriptionPlanDestroy: {
        id: 'subscriptionPlanDestroy',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      subscriptionPlanRead: {
        id: 'subscriptionPlanRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      subscriptionPlanAutocomplete: {
        id: 'subscriptionPlanAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      subscriptionImport: {
        id: 'subscriptionImport',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      subscriptionCreate: {
        id: 'subscriptionCreate',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      subscriptionEdit: {
        id: 'subscriptionEdit',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      subscriptionDestroy: {
        id: 'subscriptionDestroy',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      subscriptionRead: {
        id: 'subscriptionRead',
        allowedRoles: [
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      subscriptionAutocomplete: {
        id: 'subscriptionAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      paymentImport: {
        id: 'paymentImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      paymentCreate: {
        id: 'paymentCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      paymentEdit: {
        id: 'paymentEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      paymentDestroy: {
        id: 'paymentDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      paymentRead: {
        id: 'paymentRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      paymentAutocomplete: {
        id: 'paymentAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      trainerSettingsImport: {
        id: 'trainerSettingsImport',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerSettingsCreate: {
        id: 'trainerSettingsCreate',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.trainerSettingsBrandingLogo,
          storage.trainerSettingsProfilePicture,
        ],
      },
      trainerSettingsEdit: {
        id: 'trainerSettingsEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.trainerSettingsBrandingLogo,
          storage.trainerSettingsProfilePicture,
        ],
      },
      trainerSettingsDestroy: {
        id: 'trainerSettingsDestroy',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.trainerSettingsBrandingLogo,
          storage.trainerSettingsProfilePicture,
        ],
      },
      trainerSettingsRead: {
        id: 'trainerSettingsRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerSettingsAutocomplete: {
        id: 'trainerSettingsAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerPublicProfileRead: {
        id: 'trainerPublicProfileRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      videoCategoryImport: {
        id: 'videoCategoryImport',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      videoCategoryCreate: {
        id: 'videoCategoryCreate',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      videoCategoryEdit: {
        id: 'videoCategoryEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      videoCategoryDestroy: {
        id: 'videoCategoryDestroy',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      videoCategoryRead: {
        id: 'videoCategoryRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      videoCategoryAutocomplete: {
        id: 'videoCategoryAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      videoLibraryImport: {
        id: 'videoLibraryImport',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      videoLibraryCreate: {
        id: 'videoLibraryCreate',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.videoLibraryFile],
      },
      videoLibraryEdit: {
        id: 'videoLibraryEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.videoLibraryFile],
      },
      videoLibraryDestroy: {
        id: 'videoLibraryDestroy',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.videoLibraryFile],
      },
      videoLibraryRead: {
        id: 'videoLibraryRead',
        allowedRoles: [
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      videoLibraryAutocomplete: {
        id: 'videoLibraryAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      sessionTypeImport: {
        id: 'sessionTypeImport',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      sessionTypeCreate: {
        id: 'sessionTypeCreate',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      sessionTypeEdit: {
        id: 'sessionTypeEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      sessionTypeDestroy: {
        id: 'sessionTypeDestroy',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      sessionTypeRead: {
        id: 'sessionTypeRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      sessionTypeAutocomplete: {
        id: 'sessionTypeAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      trainerSessionPlanImport: {
        id: 'trainerSessionPlanImport',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerSessionPlanCreate: {
        id: 'trainerSessionPlanCreate',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerSessionPlanEdit: {
        id: 'trainerSessionPlanEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerSessionPlanDestroy: {
        id: 'trainerSessionPlanDestroy',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerSessionPlanRead: {
        id: 'trainerSessionPlanRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerSessionPlanAutocomplete: {
        id: 'trainerSessionPlanAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      trainerFreeSlotImport: {
        id: 'trainerFreeSlotImport',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerFreeSlotCreate: {
        id: 'trainerFreeSlotCreate',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerFreeSlotEdit: {
        id: 'trainerFreeSlotEdit',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerFreeSlotDestroy: {
        id: 'trainerFreeSlotDestroy',
        allowedRoles: [roles.admin, roles.trainer],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerFreeSlotRead: {
        id: 'trainerFreeSlotRead',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerFreeSlotAutocomplete: {
        id: 'trainerFreeSlotAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      trainerCaledarSessionImport: {
        id: 'trainerCaledarSessionImport',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerCaledarSessionCreate: {
        id: 'trainerCaledarSessionCreate',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerCaledarSessionEdit: {
        id: 'trainerCaledarSessionEdit',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerCaledarSessionDestroy: {
        id: 'trainerCaledarSessionDestroy',
        allowedRoles: [
          roles.admin,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      trainerCaledarSessionRead: {
        id: 'trainerCaledarSessionRead',
        allowedRoles: [
            roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      trainerCaledarSessionAutocomplete: {
        id: 'trainerCaledarSessionAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      adminPricing: {
        id: 'adminPricing',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.trainer,
          roles.client,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      onBoarding: {
        id: 'onBoarding',
        allowedRoles: [roles.trainer],
      },
      financePaymentAdmin: {
        id: 'financePaymentAdmin',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      financeSubscriptionAdmin: {
        id: 'financeSubscriptionAdmin',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
