import actions from './liveVideoActions';

const initialData = {
  room: {
    identity: '',
    token: '',
  },
  loading: false,
  errorMsg: '',
};

const liveVideoReducer = (
  state = initialData,
  { type, payload },
) => {
  switch (type) {
    case actions.ROOM_FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case actions.ROOM_FETCH_SUCCESS:
      return {
        ...state,
        room: payload.room,
        loading: false,
      };
    case actions.ROOM_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: payload.error,
      };
    default:
      return state;
  }
};

export default liveVideoReducer;
