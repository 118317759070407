import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/subscriptionPlan/importer/subscriptionPlanImporterSelectors';
import SubscriptionPlanService from 'src/modules/subscriptionPlan/subscriptionPlanService';
import fields from 'src/modules/subscriptionPlan/importer/subscriptionPlanImporterFields';
import { i18n } from 'src/i18n';

const subscriptionPlanImporterActions = importerActions(
  'SUBSCRIPTIONPLAN_IMPORTER',
  selectors,
  SubscriptionPlanService.import,
  fields,
  i18n('entities.subscriptionPlan.importer.fileName'),
);

export default subscriptionPlanImporterActions;
