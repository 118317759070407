import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.trainerCaledarSession.fields.id'),
  },
  {
    name: 'trainerId',
    label: i18n(
      'entities.trainerCaledarSession.fields.trainerId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'startTime',
    label: i18n(
      'entities.trainerCaledarSession.fields.startTime',
    ),
  },
  {
    name: 'endTime',
    label: i18n(
      'entities.trainerCaledarSession.fields.endTime',
    ),
  },
  {
    name: 'startTimeWithBuffer',
    label: i18n(
      'entities.trainerCaledarSession.fields.startTimeWithBuffer',
    ),
  },
  {
    name: 'endTimeWithBuffer',
    label: i18n(
      'entities.trainerCaledarSession.fields.endTimeWithBuffer',
    ),
  },
  {
    name: 'sessionPlanId',
    label: i18n(
      'entities.trainerCaledarSession.fields.sessionPlanId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'paymentId',
    label: i18n(
      'entities.trainerCaledarSession.fields.paymentId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'cost',
    label: i18n(
      'entities.trainerCaledarSession.fields.cost',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'discount',
    label: i18n(
      'entities.trainerCaledarSession.fields.discount',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'conferenceUrl',
    label: i18n(
      'entities.trainerCaledarSession.fields.conferenceUrl',
    ),
  },
  {
    name: 'status',
    label: i18n(
      'entities.trainerCaledarSession.fields.status',
    ),
  },
  {
    name: 'trainerTransferId',
    label: i18n(
      'entities.trainerCaledarSession.fields.trainerTransferId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.trainerCaledarSession.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.trainerCaledarSession.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
