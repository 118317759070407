import actions from './chatActions';
import {Conversation} from '@twilio/conversations/lib/conversation';

export interface LastMessageLocalConversation {
  sid: string;
  author: string;
  message: string;
  date: Date;
}

export interface LocalConversation extends Conversation {
  lastMsg: LastMessageLocalConversation;
}

export interface LocalConversationUnreadMessage {
  conversationSID: string;
  hasUnreadMessage: boolean;
}

export interface ChatReducerState {
  chatToken: {
    identity: string,
    token: string,
    email: string,
    fullName: string,
  },
  conversations: LocalConversation[],
  activeConversation: LocalConversation | null,
  unreadMessages: LocalConversationUnreadMessage[],
  loading: boolean,
  errorMsg: string,
}

const initialData: ChatReducerState = {
  chatToken: {
    identity:'',
    token:'',
    email:'',
    fullName:'',
  },
  conversations: [],
  activeConversation: null,
  unreadMessages: [],
  loading: false,
  errorMsg: '',
};

const chatReducer = (state = initialData, { type, payload }) => {
  switch (type) {
    case actions.TOKEN_FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case actions.TOKEN_FETCH_SUCCESS:
      return {
        ...state,
        chatToken: payload.chatToken,
        loading: false,
      };
    case actions.TOKEN_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: payload.error,
      };
    case actions.CONVERSATION_JOIN:
      // handle duplicated conversations
      const conversationExists = state.conversations.find((c) => c.sid === payload.conversation.sid);
      if (conversationExists) {
        return {
          ...state
        };
      }

      return {
        ...state,
        // TODO: maybe there is a better way than sorting them here
        conversations: [
          ...state.conversations,
          payload.conversation
        ].sort((a, b) => {
          const aDate = a.lastMessage ? a.lastMessage.dateCreated : a.dateCreated;
          const bDate = b.lastMessage ? b.lastMessage.dateCreated : b.dateCreated;

          return bDate - aDate;
        })
      }
    case actions.CONVERSATION_LEAVE:
      return {
        ...state,
        conversations: state.conversations.filter((c) => c.sid !== payload.conversation.sid),
        unreadMessages: state.unreadMessages.filter((m) => m.conversationSID !== payload.conversation.sid)
      }
    case actions.CONVERSATION_CLEAR:
      return {
        ...state,
        conversations: [],
        unreadMessages: [],
        activeConversation: null,
      }  
    case actions.CONVERSATION_SET_ACTIVE:
      return {
        ...state,
        activeConversation: payload.conversation,
      }
    case actions.CONVERSATION_SET_UNREAD_MESSAGES:
      const unreadMessageIndex = state.unreadMessages.findIndex((m) => m.conversationSID === payload.conversationSID);
      if (unreadMessageIndex !== -1) {
        const unreadMessages = [...state.unreadMessages];
        unreadMessages[unreadMessageIndex] = {
          ...unreadMessages[unreadMessageIndex],
          hasUnreadMessage: payload.readState,
        };

        return {
          ...state,
          unreadMessages,
        }
      }

      return {
        ...state,
        unreadMessages: [
          ...state.unreadMessages,
          {
            hasUnreadMessage: payload.readState,
            conversationSID: payload.conversationSID
          }
        ]
      }
    default:
      return state;
  }
};

export default chatReducer;
