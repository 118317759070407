import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/adminSettings/importer/adminSettingsImporterSelectors';
import AdminSettingsService from 'src/modules/adminSettings/adminSettingsService';
import fields from 'src/modules/adminSettings/importer/adminSettingsImporterFields';
import { i18n } from 'src/i18n';

const adminSettingsImporterActions = importerActions(
  'ADMINSETTINGS_IMPORTER',
  selectors,
  AdminSettingsService.import,
  fields,
  i18n('entities.adminSettings.importer.fileName'),
);

export default adminSettingsImporterActions;
