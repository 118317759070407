export const LOAD_CANCELLATION_FEE_LOADING =
  'LOAD_CANCELLATION_FEE_LOADING';
export const LOAD_CANCELLATION_FEE_SUCCESS =
  'LOAD_CANCELLATION_FEE_SUCCESS';
export const LOAD_CANCELLATION_FEE_FAIL =
  'LOAD_CANCELLATION_FEE_FAIL';

export const SAVE_CANCELLATION_FEE_LOADING =
  'SAVE_CANCELLATION_FEE_LOADING';
export const SAVE_CANCELLATION_FEE_SUCCESS =
  'SAVE_CANCELLATION_FEE_SUCCESS';
export const SAVE_CANCELLATION_FEE_FAIL =
  'SAVE_CANCELLATION_FEE_FAIL';
