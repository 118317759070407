import * as types from './actionTypes';
import Errors from '../shared/error/errors';
import UserService from '../user/userService';

export const fetchSupportAdmin = () => async (dispatch) => {
  console.log("fetchSupportAdmin");
  try {
    dispatch({
      type: types.FETCH_SUPPORT_ADMIN_LOADING,
    });

    const admin = await UserService.fetchSupportAdminDetails();
    // store the items locally
    localStorage.setItem('admin_email', admin.email);
    localStorage.setItem('admin_name', admin.fullName);

    dispatch({
      type: types.FETCH_SUPPORT_ADMIN_SUCCESS,
      payload: admin,
    });
  } catch (e) {
    Errors.handle(e);
    dispatch({
      type: types.FETCH_SUPPORT_ADMIN_FAIL,
      payload: e,
    });
  }
};
