import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'trainerId',
    label: i18n('entities.sessionType.fields.trainerId'),
    schema: schemas.relationToOne(
      i18n('entities.sessionType.fields.trainerId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.sessionType.fields.name'),
    schema: schemas.string(
      i18n('entities.sessionType.fields.name'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.sessionType.fields.description'),
    schema: schemas.string(
      i18n('entities.sessionType.fields.description'),
      {},
    ),
  },
];
