import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/trainerFreeSlot/importer/trainerFreeSlotImporterSelectors';
import TrainerFreeSlotService from 'src/modules/trainerFreeSlot/trainerFreeSlotService';
import fields from 'src/modules/trainerFreeSlot/importer/trainerFreeSlotImporterFields';
import { i18n } from 'src/i18n';

const trainerFreeSlotImporterActions = importerActions(
  'TRAINERFREESLOT_IMPORTER',
  selectors,
  TrainerFreeSlotService.import,
  fields,
  i18n('entities.trainerFreeSlot.importer.fileName'),
);

export default trainerFreeSlotImporterActions;
