import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class ClientSettingsService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/client-settings/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/client-settings`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/client-settings`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/client-settings/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/client-settings/${id}`,
    );

    return response.data;
  }
  //limit and offset could be a number but if we have an export it could be null
  static async list(
    filter,
    orderBy?,
    limit?: any,
    offset?: any,
  ) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/my-client`,
      {
        params: {
          orderby: filter.orderby,
          search: filter.search,
          limit: limit,
          offset: offset,
          ...filter,
        },
      },
    );

    return response.data;
  }
  //limit and offset could be a number but if we have an export it could be null
  static async getlistClientTrainer(
    filter,
    orderBy?,
    limit?: any,
    offset?: any,
  ) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/my-trainer`,
      {
        params: {
          orderby: filter.orderby,
          search: filter.search,
          limit: limit,
          offset: offset,
        },
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/client-settings/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}
