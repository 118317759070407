import authAxios from 'src/modules/shared/axios/authAxios';

export default class TrainerService {
  static async fetchTrainers(params?: {
    page?: number;
    languageId?: string;
    timezoneId?: string;
    categoryIds?: string;
    individualSession?: boolean;
  }) {
    const response = await authAxios.get(`/trainer`, {
      params,
    });
    return response.data;
  }

  static async fetchLocations() {
    const response = await authAxios.get(`/timezone`);
    return response.data;
  }

  static async fetchLanguages() {
    const response = await authAxios.get(`/language`);
    return response.data;
  }

  static async fetchTrainer(id: string) {
    const response = await authAxios.get(`/trainer/${id}`);
    return response.data;
  }

  static async fetchCategories() {
    const response = await authAxios.get(`/category`);
    return response.data;
  }

  static async fetchVideo(id: string) {
    const response = await authAxios.get(
      `/trainer/${id}/video`,
    );
    return response.data;
  }
}
