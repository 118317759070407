import ClientSettingsService from 'src/modules/clientSettings/clientSettingsService';
import selectors from 'src/modules/clientSettings/list/clientSettingsListSelectors';
import { i18n } from 'src/i18n';
import exporterFields from 'src/modules/clientSettings/list/clientSettingsListExporterFields';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'CLIENTSETTINGS_LIST';

const clientSettingsListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  CLIENT_CHANGED: `${prefix}_CLIENT_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: clientSettingsListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: clientSettingsListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: clientSettingsListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: clientSettingsListActions.RESETED,
    });

    dispatch(clientSettingsListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: clientSettingsListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await ClientSettingsService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );

      new Exporter(
        exporterFields,
        i18n('entities.clientSettings.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: clientSettingsListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: clientSettingsListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: clientSettingsListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(
      clientSettingsListActions.doFetchCurrentFilter(),
    );
  },

  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: clientSettingsListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(
      clientSettingsListActions.doFetchCurrentFilter(),
    );
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        clientSettingsListActions.doFetch(
          filter,
          rawFilter,
          true,
        ),
      );
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: clientSettingsListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });
        let response;
        if (filter.role === 'client'){
          response = await ClientSettingsService.getlistClientTrainer(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          );
        }else{
          response = await ClientSettingsService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          );
        }

        dispatch({
          type: clientSettingsListActions.FETCH_SUCCESS,
          payload: response,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: clientSettingsListActions.FETCH_ERROR,
        });
      }
    },
  
  selectClient: (id) => async (dispatch) => {
    dispatch({
      type: clientSettingsListActions.CLIENT_CHANGED,
      payload: id,
    });
  },
};

export default clientSettingsListActions;
