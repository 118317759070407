import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/trainerSettings/importer/trainerSettingsImporterSelectors';
import TrainerSettingsService from 'src/modules/trainerSettings/trainerSettingsService';
import fields from 'src/modules/trainerSettings/importer/trainerSettingsImporterFields';
import { i18n } from 'src/i18n';

const trainerSettingsImporterActions = importerActions(
  'TRAINERSETTINGS_IMPORTER',
  selectors,
  TrainerSettingsService.import,
  fields,
  i18n('entities.trainerSettings.importer.fileName'),
);

export default trainerSettingsImporterActions;
