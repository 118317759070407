import * as toastr from 'toastr';
import 'toastr/build/toastr.css';

export default class Message {
  static success(arg, position='bottom-left', duration = 10) {
    // must be changed change because Bootstrap UI
    // css overrides the style
    toastr.options.toastClass = 'toastr';
    toastr.options.positionClass = `toast-${position}`;
    toastr.options.timeOut = duration * 1000;

    toastr.success(arg);
  }

  static error(arg, position='bottom-left', duration = 10) {
    // must be changed change because Bootstrap UI
    // css overrides the style
    toastr.options.toastClass = 'toastr';
    toastr.options.positionClass = `toast-${position}`;
    toastr.options.timeOut  = duration * 1000;


    toastr.error(arg);
  }

  static warning(arg , position='bottom-left', duration = 10) {
    // must be changed change because Bootstrap UI
    // css overrides the style
    toastr.options.toastClass = 'toastr';
    toastr.options.positionClass = `toast-${position}`;
    toastr.options.timeOut = duration * 1000;

    toastr.warning(arg);
  }
}

