import service from 'src/modules/contactUs/contactUsService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'CONTACT_US';

const contactUsActions = {
  CONTACT_US_SEND_START: `${prefix}_SEND_START`,
  CONTACT_US_SEND_SUCCESS: `${prefix}_SEND_SUCCESS`,
  CONTACT_US_SEND_ERROR: `${prefix}_SEND_ERROR`,

  sendContactUsForm:
    (email, subject, message) => async (dispatch) => {
      try {
        dispatch({
          type: contactUsActions.CONTACT_US_SEND_START,
        });

        const res = await service.sendContactUsForm(
          email,
          subject,
          message,
        );

        dispatch({
          type: contactUsActions.CONTACT_US_SEND_SUCCESS,
          payload: {
            res,
          },
        });
      } catch (error) {
        if (Errors.errorCode(error) !== 400) {
          Errors.handle(error);
        }

        dispatch({
          type: contactUsActions.CONTACT_US_SEND_ERROR,
          payload: Errors.selectMessage(error),
        });
      }
    },
};

export default contactUsActions;
