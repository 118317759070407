import clsx from 'clsx';
import React, { useState } from 'react';
import './ExternalLayout.scss';
import Footer from './Footer';
import Header from './Header';
import MenuLink from './MenuLink';
import config from 'src/config';

const links = [
  {
    name: 'Trainers',
    url: `/clients/trainers/`,
  },
  {
    name: 'Pricing',
    url: `${config.externalPagesUrl}/pricing/`,
  },
  {
    name: 'About Us',
    url: `${config.externalPagesUrl}/about-us/`,
  },
  {
    name: 'Features',
    url: 'javascript:;',
    sub: [
      {
        name: 'Client Management',
        url: `${config.externalPagesUrl}/features/trainer-client-management`,
      },
      {
        name: '1 on 1 Training',
        url: `${config.externalPagesUrl}/features/1-on-1-personal-training`,
      },
      {
        name: 'Group Training',
        url: `${config.externalPagesUrl}/features/group-training`,
      },
      {
        name: 'Billing & Scheduling',
        url: `${config.externalPagesUrl}/features/scheduling-and-billing`,
      },
      {
        name: 'Live Training',
        url: `${config.externalPagesUrl}/features/live-online-training`,
      },
    ],
  },
  { name: 'FAQ', url: `${config.externalPagesUrl}/faq/` },
  {
    name: 'Contact',
    url: `${config.externalPagesUrl}/contact-us/`,
  },
];

const footerLinks: MenuLink[] = [
  {
    name: 'Pricing',
    url: `${config.externalPagesUrl}/pricing/`,
  },
  {
    name: 'Client Management',
    url: `${config.externalPagesUrl}/features/trainer-client-management`,
  },
  {
    name: 'Billing & Scheduling',
    url: `${config.externalPagesUrl}/features/scheduling-and-billing`,
  },
  {
    name: 'Contact',
    url: `${config.externalPagesUrl}/contact-us/`,
  },
  {
    name: 'About Us',
    url: `${config.externalPagesUrl}/about-us/`,
  },
  {
    name: '1 on 1 Training',
    url: `${config.externalPagesUrl}/features/1-on-1-personal-training`,
  },
  {
    name: 'Group Training',
    url: `${config.externalPagesUrl}/features/group-training`,
  },
  { name: 'Sign Up | Log In', url: '/auth/signin' },
  { name: 'FAQ', url: `${config.externalPagesUrl}/faq/` },
  {
    name: 'Live Training',
    url: `${config.externalPagesUrl}/features/live-online-training`,
  },
  {
    name: 'Trainers',
    url: `/clients/trainers/`,
  },
];

const ExternalLayout = ({ children }) => {
  const [isMenuOpened, setOpenedMenu] = useState(false);

  return (
    <div
      className={clsx([
        'external-layout',
        { disableScroll: isMenuOpened },
      ])}
    >
      <Header
        links={links}
        isMenuOpened={isMenuOpened}
        setOpenedMenu={setOpenedMenu}
      />
      <div className="layout">{children}</div>
      <Footer links={footerLinks} />
    </div>
  );
};

export default ExternalLayout;
