import React, {useCallback, useLayoutEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import MenuWrapper from 'src/view/layout/styles/MenuWrapper';
import menus from 'src/view/menus';
import * as constants from '../../utils/constants';
import Message from "../shared/message";
import chatSelectors from '../../modules/chat/chatSelectors';
import MessageNotification from '../notifications/messageNotification';

function Menu(props) {
  const dispatch = useDispatch();
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const hasConversationUnreadMessages = useSelector(chatSelectors.selectConversationHasUnreadMessages);

  const permissionChecker = new PermissionChecker(currentTenant, currentUser);

  const isTrainer = new PermissionChecker(
    currentTenant,
    currentUser,
  ).validateIsTrainer();

  const isAdmin = new PermissionChecker(
    currentTenant,
    currentUser,
  ).validateIsAdmin();

  const selectedKeys = useCallback(() => {
    const url = props.url;

    const match = menus.find((option) => {
      return (
        url === option.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      return [match.path];
    }

    return [];
  }, [props.url]);

  useLayoutEffect(() => {
    if (isTrainer && !currentUser.finishedOnboard) {
      if (props.url === selectedKeys()[0]) {
        Message.warning(
          '<a href="/onboarding">Click here to complete your onboarding. ' +
          'It\'s the easiest way to start using The Trainory.</a>',
          'top-right'
        );
      }
    }

    const toggleMenuOnResize = () => {
      window.innerWidth < constants.MOBILE_BREAKPOINT
        ? dispatch(actions.doHideMenu())
        : dispatch(actions.doShowMenu());
    };

    toggleMenuOnResize();

    window.addEventListener('resize', toggleMenuOnResize);

    return () => {
      window.removeEventListener(
        'resize',
        toggleMenuOnResize,
      );
    };
  }, [
    dispatch,
    currentUser.finishedOnboard,
    isTrainer,
    selectedKeys,
    props.url
  ]);

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  const lockedForCurrentPlan = (permission) => {
    return permissionChecker.lockedForCurrentPlan(
      permission,
    );
  };

  return (
    <>
      <MenuWrapper
        style={{
          display: menuVisible ? 'block' : 'none',
          position: 'relative',
          zIndex: 3,
        }}
      >
        <div className="menu-nav border-right">
          <div className="menu-logo">
            <Link to="/">
              <img
                src="/images/logo/the-trainory-logo-white.svg"
                style={{ verticalAlign: 'middle' }}
                alt="Logo"
              />
            </Link>
          </div>

          <ul className="menu-ul">
            {menus
              .filter((menu) => match(menu.permissionRequired))
              .map((menu) => (
                <li
                  key={menu.path}
                  className={`menu-li text-nowrap 
                  ${ menu.path === '/settings' && isAdmin ? 'admin-settings-menu ' : ''} 
                  ${ menu.path === '/trainer-calendar' && isAdmin ? 'admin-settings-menu ' : ''} 
                  ${
                    selectedKeys().includes(menu.path)
                      ? 'active'
                      : ''
                  }`}
                >
                  <Link
                    to={menu.path}
                    {...(menu.label === 'Browse Trainers'
                      ? { target: '_blank' }
                      : {})}
                  >
                    <img
                      className={`${
                        selectedKeys().includes(menu.path)
                          ? 'active'
                          : ''
                      }`}
                      src={
                        '/images/icons/' +
                        menu.icon +
                        '.svg'
                      }
                      alt=""
                    />
                    <span className={'menu-label-name'}>
                      {menu.label}
                    </span>
                    {(menu.label === 'Messages' && hasConversationUnreadMessages) ? (
                      <MessageNotification />
                    ) : (
                      ''
                    )}
                  </Link>
                </li>
              ))}

            {menus
              .filter((menu) =>
                lockedForCurrentPlan(
                  menu.permissionRequired,
                ),
              )
              .map((menu) => (
                <li
                  key={menu.path}
                  className={`menu-li text-nowrap`}
                  style={{
                    cursor: 'auto',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    opacity: 0.5,
                  }}
                >
                  <div className="menu-li-locked">
                    <i className={`menu-icon ${menu.icon}`}/>{' '}
                    <span>{menu.label}</span>
                  </div>
                </li>
              ))}
          </ul>

          {isAdmin && (
            <Link
              to={'/settings'}
              className={'bottom-onboarding'}
            >
              <img
                src={'/images/icons/settings.svg'}
                alt=""
              />
              <span className={'menu-label-name'}>
                {'Account Settings'}
              </span>
            </Link>
          )}
        </div>
      </MenuWrapper>
    </>
  );
}

export default Menu;
