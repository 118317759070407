import React, { FC } from 'react';
import MenuLink from './MenuLink';
import SocialMedia from './SocialMedia';
import config from 'src/config';

export interface FooterProps {
  links: MenuLink[];
}

const Footer: FC<FooterProps> = ({ links }) => {
  return (
    <div className="footer">
      <div className="title">
        <span>
          Are you a Trainer? Check our{' '}
          <a
            className="link"
            href={`${config.externalPagesUrl}/features/trainer-client-management`}
          >
            Useful Info for Trainers{' '}
            <img
              alt="useful info for trainers"
              src="/images/icons/arrow-right.svg"
            />
          </a>
        </span>
      </div>
      <div className="content">
        <div className="layout">
          <div className="row">
            <div className="logo">
              <img src="/images/logo.svg" alt="logo" />
            </div>
            <div className="text">
              Online Personal Training for the Modern Athlete
            </div>
            <SocialMedia />
          </div>
          <div className="menu">
            {links.map((item) => {
              if (item.sub) return;
              return (
                <a href={item.url} key={item.name}>
                  <div className="link">{item.name}</div>
                </a>
              );
            })}
          </div>
          <div className="row">
            <div className="small">
              &copy; {new Date().getFullYear()}, The
              Trainory
            </div>
            <div className="small">
              <a href={`${config.externalPagesUrl}/terms`}>
                <span>Terms & Conditions</span>{' '}
                <span>Privacy Policy</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
