import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import subscriptionPlanEnumerators from 'src/modules/subscriptionPlan/subscriptionPlanEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.subscriptionPlan.fields.name'),
    schema: schemas.string(
      i18n('entities.subscriptionPlan.fields.name'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'description',
    label: i18n(
      'entities.subscriptionPlan.fields.description',
    ),
    schema: schemas.string(
      i18n('entities.subscriptionPlan.fields.description'),
      {
        required: true,
        max: 1000,
      },
    ),
  },
  {
    name: 'cost',
    label: i18n('entities.subscriptionPlan.fields.cost'),
    schema: schemas.decimal(
      i18n('entities.subscriptionPlan.fields.cost'),
      {
        required: true,
        scale: 2,
        min: 0,
      },
    ),
  },
  {
    name: 'percentPerTransaction',
    label: i18n(
      'entities.subscriptionPlan.fields.percentPerTransaction',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.subscriptionPlan.fields.percentPerTransaction',
      ),
      {
        scale: 2,
        min: 0,
        max: 100,
      },
    ),
  },
  {
    name: 'costPerTransaction',
    label: i18n(
      'entities.subscriptionPlan.fields.costPerTransaction',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.subscriptionPlan.fields.costPerTransaction',
      ),
      {
        min: 0,
        scale: 2,
      },
    ),
  },
  {
    name: 'type',
    label: i18n('entities.subscriptionPlan.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.subscriptionPlan.fields.type'),
      {
        required: true,
        options: subscriptionPlanEnumerators.type,
      },
    ),
  },
  {
    name: 'maxNumberOfClients',
    label: i18n(
      'entities.subscriptionPlan.fields.maxNumberOfClients',
    ),
    schema: schemas.integer(
      i18n(
        'entities.subscriptionPlan.fields.maxNumberOfClients',
      ),
      {
        min: 1,
      },
    ),
  },
];
