import list from 'src/modules/subscriptionPlan/list/subscriptionPlanListReducers';
import form from 'src/modules/subscriptionPlan/form/subscriptionPlanFormReducers';
import view from 'src/modules/subscriptionPlan/view/subscriptionPlanViewReducers';
import destroy from 'src/modules/subscriptionPlan/destroy/subscriptionPlanDestroyReducers';
import importerReducer from 'src/modules/subscriptionPlan/importer/subscriptionPlanImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
