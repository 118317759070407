import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/clientSettings/importer/clientSettingsImporterSelectors';
import ClientSettingsService from 'src/modules/clientSettings/clientSettingsService';
import fields from 'src/modules/clientSettings/importer/clientSettingsImporterFields';
import { i18n } from 'src/i18n';

const clientSettingsImporterActions = importerActions(
  'CLIENTSETTINGS_IMPORTER',
  selectors,
  ClientSettingsService.import,
  fields,
  i18n('entities.clientSettings.importer.fileName'),
);

export default clientSettingsImporterActions;
