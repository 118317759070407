import list from 'src/modules/videoLibrary/list/videoLibraryListReducers';
import form from 'src/modules/videoLibrary/form/videoLibraryFormReducers';
import view from 'src/modules/videoLibrary/view/videoLibraryViewReducers';
import destroy from 'src/modules/videoLibrary/destroy/videoLibraryDestroyReducers';
import importerReducer from 'src/modules/videoLibrary/importer/videoLibraryImporterReducers';
import trainersReducers from 'src/modules/videoLibrary/trainers/videoLibraryTrainersReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
  trainers: trainersReducers,
});
