import authAxios from 'src/modules/shared/axios/authAxios';

export default class ContactUsService {
  static async sendContactUsForm(email, subject, message) {
    const response = await authAxios.post('/contact-us', {
      email,
      subject,
      text: message,
    });

    return response.data;
  }
}
