import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'userId',
    label: i18n('entities.subscription.fields.userId'),
    schema: schemas.relationToOne(
      i18n('entities.subscription.fields.userId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'subscriptionPlanId',
    label: i18n(
      'entities.subscription.fields.subscriptionPlanId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.subscription.fields.subscriptionPlanId',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.subscription.fields.startDate'),
    schema: schemas.date(
      i18n('entities.subscription.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.subscription.fields.endDate'),
    schema: schemas.date(
      i18n('entities.subscription.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'sellOnDemandVideo',
    label: i18n(
      'entities.subscription.fields.sellOnDemandVideo',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.subscription.fields.sellOnDemandVideo',
      ),
      {},
    ),
  },
  {
    name: 'trainerTransferId',
    label: i18n(
      'entities.subscription.fields.trainerTransferId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.subscription.fields.trainerTransferId',
      ),
      {},
    ),
  },
  {
    name: 'paymentId',
    label: i18n('entities.subscription.fields.paymentId'),
    schema: schemas.relationToOne(
      i18n('entities.subscription.fields.paymentId'),
      {
        required: true,
      },
    ),
  },
];
