import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.trainerSettings.fields.id'),
  },
  {
    name: 'notificationEmail',
    label: i18n(
      'entities.trainerSettings.fields.notificationEmail',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'notificationSms',
    label: i18n(
      'entities.trainerSettings.fields.notificationSms',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'notificationChat',
    label: i18n(
      'entities.trainerSettings.fields.notificationChat',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'brandingLogo',
    label: i18n(
      'entities.trainerSettings.fields.brandingLogo',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'profilePicture',
    label: i18n(
      'entities.trainerSettings.fields.profilePicture',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'location',
    label: i18n('entities.trainerSettings.fields.location'),
  },
  {
    name: 'timezone',
    label: i18n('entities.trainerSettings.fields.timezone'),
  },
  {
    name: 'textColor',
    label: i18n(
      'entities.trainerSettings.fields.textColor',
    ),
  },
  {
    name: 'bufferBetweenSessions',
    label: i18n(
      'entities.trainerSettings.fields.bufferBetweenSessions',
    ),
  },
  {
    name: 'cancellationCost',
    label: i18n(
      'entities.trainerSettings.fields.cancellationCost',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'introduction',
    label: i18n(
      'entities.trainerSettings.fields.introduction',
    ),
  },
  {
    name: 'certifications',
    label: i18n(
      'entities.trainerSettings.fields.certifications',
    ),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.trainerSettings.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.trainerSettings.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
