import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.videoLibrary.fields.id'),
  },
  {
    name: 'trainerId',
    label: i18n('entities.videoLibrary.fields.trainerId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'categoryId',
    label: i18n('entities.videoLibrary.fields.categoryId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'name',
    label: i18n('entities.videoLibrary.fields.name'),
  },
  {
    name: 'deescription',
    label: i18n(
      'entities.videoLibrary.fields.deescription',
    ),
  },
  {
    name: 'file',
    label: i18n('entities.videoLibrary.fields.file'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.videoLibrary.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.videoLibrary.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
