import list from 'src/modules/videoCategory/list/videoCategoryListReducers';
import form from 'src/modules/videoCategory/form/videoCategoryFormReducers';
import view from 'src/modules/videoCategory/view/videoCategoryViewReducers';
import destroy from 'src/modules/videoCategory/destroy/videoCategoryDestroyReducers';
import importerReducer from 'src/modules/videoCategory/importer/videoCategoryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
