import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  cancellation: {
    "cancellationCost": "",
    "cancellationCostIsPercent": true,
    "cancellationCostMinutesBeforeClass": ""
  },
  errorMsg: '',
};
export default function cancellationFeeReducer(
  state = DefaultState,
  {type, payload},
) {
  switch (type) {
    case types.LOAD_CANCELLATION_FEE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.LOAD_CANCELLATION_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        cancellation: payload || null,
      };

    case types.LOAD_CANCELLATION_FEE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };
    case types.SAVE_CANCELLATION_FEE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.SAVE_CANCELLATION_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        cancellation: payload || null,
      };

    case types.SAVE_CANCELLATION_FEE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };
    default:
      return state;
  }
}
