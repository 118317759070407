import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import userPreference from 'src/modules/userPreference/userPreferenceReducers';
import adminSettings from 'src/modules/adminSettings/adminSettingsReducers';
import clientSettings from 'src/modules/clientSettings/clientSettingsReducers';
import trainerTransfer from 'src/modules/trainerTransfer/trainerTransferReducers';
import subscriptionPlan from 'src/modules/subscriptionPlan/subscriptionPlanReducers';
import subscription from 'src/modules/subscription/subscriptionReducers';
import payment from 'src/modules/payment/paymentReducers';
import trainerSettings from 'src/modules/trainerSettings/trainerSettingsReducers';
import videoCategory from 'src/modules/videoCategory/videoCategoryReducers';
import videoLibrary from 'src/modules/videoLibrary/videoLibraryReducers';
import sessionType from 'src/modules/sessionType/sessionTypeReducers';
import trainerSessionPlan from 'src/modules/trainerSessionPlan/trainerSessionPlanReducers';
import trainerFreeSlot from 'src/modules/trainerFreeSlot/trainerFreeSlotReducers';
import trainerCaledarSession from 'src/modules/trainerCalendarSession/trainerCalendarSessionReducers';
import trainerPublicProfile from './trainerPublicProfile/trainerPublicProfileReducer';
import allTimeZoneReducer from './allTimeZone/allTimeZoneReducer';
import languageReducer from './allLanguages/allLanguagesReducer';
import contactUs from 'src/modules/contactUs/contactUsReducers';
import userSettingsReducer from './userSettings/userSettingsReducer';
import trainer from 'src/modules/trainer/trainersReducers';
import categoryListReducer from './categories/categoryReducer';
import liveVideoReducer from './liveVideo/liveVideoReducers';
import { combineReducers } from 'redux';
import financeReducer from './finance/financeReducer';
import myPaymentReducer from './finance/myPayments/myPaymentsReducer';
import myEarningsReducer from './finance/myEarnings/myEarningsReducer';
import clientProfileReducer from './clientProfile/clientProfileReducer';
import adminTrainersListReducer from './adminTrainersList/adminTrainersListReducer';
import adminPurchasesReducer from './finance/admin/purchases/adminPurchasesReducer';
import adminSubscriptionReducer from './finance/admin/subscriptions/adminSubscriptionReducer';
import completeOnboardReducer from './completeOnboard/completeOnboardReducer';
import adminClientsListReducer from './adminClientsList/adminClientsListReducer';
import videoSubscriptionPlanReducer from "./videoSubscriptionPlan/videoSubscriptionPlanReducer";
import cancellationFeeReducer from "./cancellationFee/cancellationFeeReducer";
import chatReducer from './chat/chatReducers';
import adminReducer from "./finance/admin/adminReducer";
import couponsReducer from "./coupons/couponsReducer";
import myPayoutsReducer from './finance/myPayouts/myPayoutsReducer';
import myBalanceReducer from './finance/myBalance/myBalanceReducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    userPreference,
    adminSettings,
    clientSettings,
    trainerTransfer,
    subscriptionPlan,
    subscription,
    payment,
    trainerSettings,
    videoCategory,
    videoLibrary,
    sessionType,
    trainerSessionPlan,
    trainerFreeSlot,
    trainerCaledarSession,
    trainerPublicProfile,
    allTimeZoneReducer,
    languageReducer,
    contactUs,
    trainer,
    userSettings: userSettingsReducer,
    categoryList: categoryListReducer,
    finance: financeReducer,
    myPayments: myPaymentReducer,
    myEarnings: myEarningsReducer,
    clientProfile: clientProfileReducer,
    adminTrainerList: adminTrainersListReducer,
    adminPurchases: adminPurchasesReducer,
    adminSubscription: adminSubscriptionReducer,
    onBoardingComplete: completeOnboardReducer,
    adminClientList: adminClientsListReducer,
    videoSubscriptionPlan: videoSubscriptionPlanReducer,
    cancellationFee: cancellationFeeReducer,
    liveVideoReducer,
    chatReducer,
    adminReducer,
    coupons: couponsReducer,
    myPayouts: myPayoutsReducer,
    myBalance: myBalanceReducer
  });
