export const GET_USER_SETTINGS_LOADING =
  'GET_USER_SETTINGS_LOADING';
export const GET_USER_SETTINGS_SUCCESS =
  'GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_FAIL =
  'GET_USER_SETTINGS_FAIL';

export const UPDATE_USER_SETTINGS_LOADING =
  'UPDATE_USER_SETTINGS_LOADING';
export const UPDATE_USER_SETTINGS_SUCCESS =
  'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAIL =
  'UPDATE_USER_SETTINGS_FAIL';

export const DELETE_USER_SETTINGS_LOADING =
    'DELETE_USER_SETTINGS_LOADING';
export const DELETE_USER_SETTINGS_SUCCESS =
    'DELETE_USER_SETTINGS_SUCCESS';
export const DELETE_USER_SETTINGS_FAIL =
    'DELETE_USER_SETTINGS_FAIL';
