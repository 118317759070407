import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import trainerTransferEnumerators from 'src/modules/trainerTransfer/trainerTransferEnumerators';

export default [
  {
    name: 'trainerId',
    label: i18n(
      'entities.trainerTransfer.fields.trainerId',
    ),
    schema: schemas.relationToOne(
      i18n('entities.trainerTransfer.fields.trainerId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'externalId',
    label: i18n(
      'entities.trainerTransfer.fields.externalId',
    ),
    schema: schemas.string(
      i18n('entities.trainerTransfer.fields.externalId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'date',
    label: i18n('entities.trainerTransfer.fields.date'),
    schema: schemas.date(
      i18n('entities.trainerTransfer.fields.date'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'originalAmount',
    label: i18n(
      'entities.trainerTransfer.fields.originalAmount',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.trainerTransfer.fields.originalAmount',
      ),
      {
        required: true,
        min: 0,
        scale: 2,
      },
    ),
  },
  {
    name: 'feesApplied',
    label: i18n(
      'entities.trainerTransfer.fields.feesApplied',
    ),
    schema: schemas.decimal(
      i18n('entities.trainerTransfer.fields.feesApplied'),
      {
        required: true,
        min: 0,
        scale: 2,
      },
    ),
  },
  {
    name: 'discount',
    label: i18n('entities.trainerTransfer.fields.discount'),
    schema: schemas.decimal(
      i18n('entities.trainerTransfer.fields.discount'),
      {
        required: true,
        min: 0,
        scale: 2,
      },
    ),
  },
  {
    name: 'totalTransfered',
    label: i18n(
      'entities.trainerTransfer.fields.totalTransfered',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.trainerTransfer.fields.totalTransfered',
      ),
      {
        required: true,
        scale: 2,
        min: 0,
      },
    ),
  },
  {
    name: 'description',
    label: i18n(
      'entities.trainerTransfer.fields.description',
    ),
    schema: schemas.string(
      i18n('entities.trainerTransfer.fields.description'),
      {
        max: 800,
      },
    ),
  },
  {
    name: 'status',
    label: i18n('entities.trainerTransfer.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.trainerTransfer.fields.status'),
      {
        required: true,
        options: trainerTransferEnumerators.status,
      },
    ),
  },
];
