/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      trainerSettingsBrandingLogo: {
        id: 'trainerSettingsBrandingLogo',
        folder:
          'tenant/:tenantId/trainerSettings/brandingLogo',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      trainerSettingsProfilePicture: {
        id: 'trainerSettingsProfilePicture',
        folder:
          'tenant/:tenantId/trainerSettings/profilePicture',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      trainerSettingsCover: {
        id: 'trainerSettingsCover',
        folder: 'tenant/:tenantId/trainerSettings/cover',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      trainerSettingsGallery: {
        id: 'trainerSettingsGallery',
        folder: 'tenant/:tenantId/trainerSettings/gallery',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      videoLibraryFile: {
        id: 'videoLibraryFile',
        folder: 'tenant/:tenantId/videoLibrary/file',
        maxSizeInBytes: 1000 * 1024 * 1024,
      },
      videoLibraryThumbnails: {
        id: 'videoLibraryThumbnails',
        folder: 'tenant/:tenantId/videoLibrary/thumbnails',
        maxSizeInBytes: 500 * 1024 * 1024,
      },
      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      clientSettingsProfilePicture: {
        id: 'clientSettingsProfilePicture',
        folder:
          'tenant/:tenantId/clientSettings/profilePicture',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      clientSettingsCover: {
        id: 'clientSettingsCover',
        folder: 'tenant/:tenantId/clientSettings/cover',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
    };
  }
}
