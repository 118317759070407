import {createSelector} from 'reselect';

const chatState = (state) => state.chatReducer;

const selectToken = createSelector([chatState], (raw) => raw.chatToken);
const selectConversations = createSelector([chatState], (raw) => raw.conversations);
const selectActiveConversation = createSelector([chatState], (raw) => raw.activeConversation);
const selectUnreadMessages = createSelector([chatState], (raw) => raw.unreadMessages);
const selectConversationHasUnreadMessages = createSelector([chatState], (raw) => {
  return raw.unreadMessages.filter((m) => m.hasUnreadMessage).length > 0;
});

const chatSelectors = {
  selectToken,
  selectConversations,
  selectActiveConversation,
  selectUnreadMessages,
  selectConversationHasUnreadMessages,
};

export default chatSelectors;
