import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'userId',
    label: i18n('entities.clientSettings.fields.userId'),
    schema: schemas.relationToOne(
      i18n('entities.clientSettings.fields.userId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'liabilityWaiverDate',
    label: i18n(
      'entities.clientSettings.fields.liabilityWaiverDate',
    ),
    schema: schemas.date(
      i18n(
        'entities.clientSettings.fields.liabilityWaiverDate',
      ),
      {},
    ),
  },
  {
    name: 'healthQuestionaireDate',
    label: i18n(
      'entities.clientSettings.fields.healthQuestionaireDate',
    ),
    schema: schemas.date(
      i18n(
        'entities.clientSettings.fields.healthQuestionaireDate',
      ),
      {},
    ),
  },
];
