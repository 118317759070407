import service from 'src/modules/chat/chatService';
import Errors from 'src/modules/shared/error/errors';
import {Conversation} from '@twilio/conversations/lib/conversation';
import chatSelectors from './chatSelectors';

const chatActions = {
  TOKEN_FETCH_START: 'TOKEN_FETCH_START',
  TOKEN_FETCH_SUCCESS: 'TOKEN_FETCH_SUCCESS',
  TOKEN_FETCH_ERROR: 'TOKEN_FETCH_ERROR',

  CONVERSATION_JOIN: 'CONVERSATION_JOIN',
  CONVERSATION_LEAVE: 'CONVERSATION_LEAVE',
  CONVERSATION_CLEAR: 'CONVERSATION_CLEAR',
  CONVERSATION_SET_ACTIVE: 'CONVERSATION_SET_ACTIVE',
  CONVERSATION_SET_LAST_MESSAGE: 'CONVERSATION_SET_LAST_MESSAGE',
  CONVERSATION_SET_UNREAD_MESSAGES: 'CONVERSATION_SET_UNREAD_MESSAGES',

  doTokenFetch: () => async (dispatch) => {
    try {
      dispatch({ type:chatActions.TOKEN_FETCH_START });
      const chatToken = await service.fetchToken();
      dispatch({ type:chatActions.TOKEN_FETCH_SUCCESS, payload:{chatToken} });
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: chatActions.TOKEN_FETCH_ERROR, payload: error });
    }
  },

  doConversationJoin: (conversation: Conversation) => async (dispatch) => {
    dispatch({ type: chatActions.CONVERSATION_JOIN, payload: { conversation }});
  },

  doConversationLeave: (conversation: Conversation) => async (dispatch) => {
    dispatch({ type: chatActions.CONVERSATION_LEAVE, payload: { conversation }});
  },

  doConversationClear: () => async (dispatch) => {
    dispatch({ type: chatActions.CONVERSATION_CLEAR, payload: { }});
  },

  doConversationSetActive: (conversation: Conversation | null) => async (dispatch) => {
    dispatch({ type: chatActions.CONVERSATION_SET_ACTIVE, payload: { conversation }});
  },

  doConversationSetLastMessage: (conversationSID: string, message) => async (dispatch) => {
    dispatch({ type: chatActions.CONVERSATION_SET_LAST_MESSAGE, payload: { conversationSID, message }});
  },

  doConversationOnNewMessage: (conversationSID: string) => async (dispatch, getState) => {
    const activeConversation = chatSelectors.selectActiveConversation(getState());
    if (activeConversation?.sid !== conversationSID) {
      dispatch({ type: chatActions.CONVERSATION_SET_UNREAD_MESSAGES, payload: { conversationSID, readState: true }});
    }
  },

  doConversationSetUnreadMessages: (conversationSID: string, readState: boolean) => async (dispatch, state) => {
    dispatch({ type: chatActions.CONVERSATION_SET_UNREAD_MESSAGES, payload: { conversationSID, readState }});
  },
};

export default chatActions;
