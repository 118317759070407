import list from 'src/modules/sessionType/list/sessionTypeListReducers';
import form from 'src/modules/sessionType/form/sessionTypeFormReducers';
import view from 'src/modules/sessionType/view/sessionTypeViewReducers';
import destroy from 'src/modules/sessionType/destroy/sessionTypeDestroyReducers';
import importerReducer from 'src/modules/sessionType/importer/sessionTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
