import list from 'src/modules/adminSettings/list/adminSettingsListReducers';
import form from 'src/modules/adminSettings/form/adminSettingsFormReducers';
import view from 'src/modules/adminSettings/view/adminSettingsViewReducers';
import destroy from 'src/modules/adminSettings/destroy/adminSettingsDestroyReducers';
import importerReducer from 'src/modules/adminSettings/importer/adminSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
