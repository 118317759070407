import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'notificationEmail',
    label: i18n(
      'entities.trainerSettings.fields.notificationEmail',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.trainerSettings.fields.notificationEmail',
      ),
      {},
    ),
  },
  {
    name: 'notificationSms',
    label: i18n(
      'entities.trainerSettings.fields.notificationSms',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.trainerSettings.fields.notificationSms',
      ),
      {},
    ),
  },
  {
    name: 'notificationChat',
    label: i18n(
      'entities.trainerSettings.fields.notificationChat',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.trainerSettings.fields.notificationChat',
      ),
      {},
    ),
  },
  {
    name: 'brandingLogo',
    label: i18n(
      'entities.trainerSettings.fields.brandingLogo',
    ),
    schema: schemas.images(
      i18n('entities.trainerSettings.fields.brandingLogo'),
      {},
    ),
  },
  {
    name: 'profilePicture',
    label: i18n(
      'entities.trainerSettings.fields.profilePicture',
    ),
    schema: schemas.images(
      i18n(
        'entities.trainerSettings.fields.profilePicture',
      ),
      {},
    ),
  },
  {
    name: 'location',
    label: i18n('entities.trainerSettings.fields.location'),
    schema: schemas.string(
      i18n('entities.trainerSettings.fields.location'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'timezone',
    label: i18n('entities.trainerSettings.fields.timezone'),
    schema: schemas.string(
      i18n('entities.trainerSettings.fields.timezone'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'textColor',
    label: i18n(
      'entities.trainerSettings.fields.textColor',
    ),
    schema: schemas.string(
      i18n('entities.trainerSettings.fields.textColor'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'bufferBetweenSessions',
    label: i18n(
      'entities.trainerSettings.fields.bufferBetweenSessions',
    ),
    schema: schemas.integer(
      i18n(
        'entities.trainerSettings.fields.bufferBetweenSessions',
      ),
      {
        min: 0,
        max: 60,
        required: true,
      },
    ),
  },
  {
    name: 'cancellationCost',
    label: i18n(
      'entities.trainerSettings.fields.cancellationCost',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.trainerSettings.fields.cancellationCost',
      ),
      {
        required: true,
        scale: 2,
        min: 0,
      },
    ),
  },
  {
    name: 'introduction',
    label: i18n(
      'entities.trainerSettings.fields.introduction',
    ),
    schema: schemas.string(
      i18n('entities.trainerSettings.fields.introduction'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'certifications',
    label: i18n(
      'entities.trainerSettings.fields.certifications',
    ),
    schema: schemas.string(
      i18n(
        'entities.trainerSettings.fields.certifications',
      ),
      {},
    ),
  },
];
