export const LOAD_PUBLIC_TRAINER_LOADING =
  'LOAD_PUBLIC_TRAINER_LOADING';
export const LOAD_PUBLIC_TRAINER_SUCCESS =
  'LOAD_PUBLIC_TRAINER_SUCCESS';
export const LOAD_PUBLIC_TRAINER_FAIL =
  'LOAD_PUBLIC_TRAINER_FAIL';

export const UPDATE_PUBLIC_TRAINER_LOADING =
  'UPDATE_PUBLIC_TRAINER_LOADING';
export const UPDATE_PUBLIC_TRAINER_SUCCESS =
  'UPDATE_PUBLIC_TRAINER_SUCCESS';
export const UPDATE_PUBLIC_TRAINER_FAIL =
  'UPDATE_PUBLIC_TRAINER_FAIL';

export const CREATE_CERTIFICATION_LOADING =
  'CREATE_CERTIFICATION_LOADING';
export const CREATE_CERTIFICATION_SUCCESS =
  'CREATE_CERTIFICATION_SUCCESS';
export const CREATE_CERTIFICATION_FAIL =
  'CREATE_CERTIFICATION_FAIL';

export const DELETE_CERTIFICATION_LOADING =
  'DELETE_CERTIFICATION_LOADING';
export const DELETE_CERTIFICATION_SUCCESS =
  'DELETE_CERTIFICATION_SUCCESS';
export const DELETE_CERTIFICATION_FAIL =
  'DELETE_CERTIFICATION_FAIL';
