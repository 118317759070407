import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';

const permissions = Permissions.values;

export default [
  {
    path: '/admin-trainers',
    permissionRequired: permissions.adminTrainers,
    icon: 'publicProfile',
    label: 'Trainers',
  },
  {
    path: '/admin-clients',
    permissionRequired: permissions.adminClients,
    icon: 'clients',
    label: 'Clients',
  },
  {
    path: '/trainer-calendar',
    permissionRequired:
      permissions.trainerCaledarSessionRead,
    icon: 'calendar',
    label: i18n('entities.trainerCaledarSession.menu'),
  },
  {
    path: '/messages',
    icon: 'message',
    label: i18n('entities.messages.menu'),
  },
  {
    path: '/trainer-public-profile',
    permissionRequired: permissions.clientSettingsRead,
    icon: 'publicProfile',
    label: i18n('entities.publicProfile.menu'),
  },

  {
    path: '/client-settings',
    permissionRequired: permissions.clientSettingsRead,
    icon: 'clients',
    label: i18n('entities.clientSettings.menu'),
  },

  {
    path: '/my-trainers',
    permissionRequired: permissions.clientSettingsClient,
    icon: 'clients',
    label: 'My Trainers',
  },
  {
    path: '/clients/trainers/',
    permissionRequired: permissions.clientSettingsClient,
    icon: 'clients',
    label: 'Browse Trainers',
  },
  {
    path: '/subscription',
    permissionRequired: permissions.subscriptionRead,
    icon: 'finance',
    label: i18n('entities.subscription.menu'),
  },

  {
    path: '/finance/admin',
    permissionRequired: permissions.financePaymentAdmin,
    icon: 'finance',
    label: i18n('entities.subscription.menu'),
  },

  {
    path: '/video-library',
    permissionRequired: permissions.videoLibraryRead,
    icon: 'onDemand',
    label: i18n('entities.videoLibrary.menu'),
  },

  {
    path: '/video-conference',
    permissionRequired: permissions.videoLibraryRead,
    icon: 'videoConferen',
    label: i18n('entities.videoConference.menu'),
  },

  {
    path: '/client-profile',
    permissionRequired: permissions.clientSettingsClient,
    icon: 'publicProfile',
    label: 'Profile',
  },

  {
    path: '/settings',
    icon: 'settings',
    label: i18n('settings.menu'),
  },
  {
    path: '/admin-pricing',
    permissionRequired: permissions.settingsEdit,
    icon: 'finance',
    label: i18n('entities.adminPricing.menu'),
  },
  {
    path: '/onboarding',
    permissionRequired: permissions.clientSettingsRead,
    icon: 'onboarding',
    label: 'Get Started',
  },

  // config.isPlanEnabled && {
  //   path: '/plan',
  //   permissionRequired: permissions.planRead,
  //   icon: 'fas fa-credit-card',
  //   label: i18n('plan.menu'),
  // },
  //
  // {
  //   path: '/user',
  //   label: i18n('user.menu'),
  //   permissionRequired: permissions.userRead,
  //   icon: 'fas fa-user-plus',
  // },
  //
  // {
  //   path: '/audit-logs',
  //   icon: 'fas fa-history',
  //   label: i18n('auditLog.menu'),
  //   permissionRequired: permissions.auditLogRead,
  // },
  //
  //
  //
  // {
  //   path: '/user-preference',
  //   permissionRequired: permissions.userPreferenceRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.userPreference.menu'),
  // },
  //
  // {
  //   path: '/admin-settings',
  //   permissionRequired: permissions.adminSettingsRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.adminSettings.menu'),
  // },
  //
  //
  // {
  //   path: '/trainer-transfer',
  //   permissionRequired: permissions.trainerTransferRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.trainerTransfer.menu'),
  // },
  //
  // {
  //   path: '/subscription-plan',
  //   permissionRequired: permissions.subscriptionPlanRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.subscriptionPlan.menu'),
  // },
  //
  //
  // {
  //   path: '/payment',
  //   permissionRequired: permissions.paymentRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.payment.menu'),
  // },
  //
  // {
  //   path: '/trainer-settings',
  //   permissionRequired: permissions.trainerSettingsRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.trainerSettings.menu'),
  // },
  //
  // {
  //   path: '/video-category',
  //   permissionRequired: permissions.videoCategoryRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.videoCategory.menu'),
  // },
  //
  //
  //
  // {
  //   path: '/session-type',
  //   permissionRequired: permissions.sessionTypeRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.sessionType.menu'),
  // },
  //
  // {
  //   path: '/trainer-session-plan',
  //   permissionRequired: permissions.trainerSessionPlanRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.trainerSessionPlan.menu'),
  // },
  //
  // {
  //   path: '/trainer-free-slot',
  //   permissionRequired: permissions.trainerFreeSlotRead,
  //   icon: 'fas fa-chevron-right',
  //   label: i18n('entities.trainerFreeSlot.menu'),
  // },
].filter(Boolean);
