import Message from "src/view/shared/message";

export function showClientLiabilityWaiverWarning() {
  Message.warning(
    '<a href="/client-profile/questionaire">Before you can schedule a session, ' +
    'please accept the Liability Waiver here. ' +
    'Also, answering the questionnaire will help your Trainer prepare your workout.</a>',
    'top-right'
  );
}
