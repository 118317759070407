import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.videoCategory.fields.id'),
  },
  {
    name: 'trainerId',
    label: i18n('entities.videoCategory.fields.trainerId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'name',
    label: i18n('entities.videoCategory.fields.name'),
  },
  {
    name: 'description',
    label: i18n(
      'entities.videoCategory.fields.description',
    ),
  },
  {
    name: 'createdAt',
    label: i18n('entities.videoCategory.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.videoCategory.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
