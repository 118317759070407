import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/sessionType/importer/sessionTypeImporterSelectors';
import SessionTypeService from 'src/modules/sessionType/sessionTypeService';
import fields from 'src/modules/sessionType/importer/sessionTypeImporterFields';
import { i18n } from 'src/i18n';

const sessionTypeImporterActions = importerActions(
  'SESSIONTYPE_IMPORTER',
  selectors,
  SessionTypeService.import,
  fields,
  i18n('entities.sessionType.importer.fileName'),
);

export default sessionTypeImporterActions;
