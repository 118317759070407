import * as actionTypes from './actionTypes';

const DefaultState = {
  loading: false,
  client: {},
  errorMsg: '',
};
const clientProfileReducer = (
  state = DefaultState,
  { type, payload },
) => {
  switch (type) {
    case actionTypes.GET_CLIENT_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        client: payload,
        loading: false,
      };
    case actionTypes.GET_CLIENT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case actionTypes.UPDATE_CLIENT_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case actionTypes.UPDATE_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        client: payload || null,
      };

    case actionTypes.UPDATE_CLIENT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    default:
      return state;
  }
};

export default clientProfileReducer;
