import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'trainerId',
    label: i18n(
      'entities.trainerFreeSlot.fields.trainerId',
    ),
    schema: schemas.relationToOne(
      i18n('entities.trainerFreeSlot.fields.trainerId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startTime',
    label: i18n(
      'entities.trainerFreeSlot.fields.startTime',
    ),
    schema: schemas.date(
      i18n('entities.trainerFreeSlot.fields.startTime'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endTime',
    label: i18n('entities.trainerFreeSlot.fields.endTime'),
    schema: schemas.date(
      i18n('entities.trainerFreeSlot.fields.endTime'),
      {
        required: true,
      },
    ),
  },
];
