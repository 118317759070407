import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  data: {} as any,
  errorMsg: '',
};
export default function trainerReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.LOAD_PUBLIC_TRAINER_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.LOAD_PUBLIC_TRAINER_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        data: payload || null,
      };

    case types.LOAD_PUBLIC_TRAINER_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.UPDATE_PUBLIC_TRAINER_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.UPDATE_PUBLIC_TRAINER_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        data: payload || null,
      };

    case types.UPDATE_PUBLIC_TRAINER_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.CREATE_CERTIFICATION_LOADING:
      return {
        ...state,
        // loading: true,
        errorMsg: '',
      };

    case types.CREATE_CERTIFICATION_SUCCESS:
      return {
        ...state,
        // loading: false,
        errorMsg: '',
        data: {
          ...state.data,
          certifications: [
            ...state.data.certifications,
            payload,
          ],
        },
      };

    case types.CREATE_CERTIFICATION_FAIL:
      return {
        ...state,
        // loading: false,
        errorMsg: payload || null,
      };

    case types.DELETE_CERTIFICATION_LOADING:
      return {
        ...state,
        // loading: true,
        errorMsg: '',
      };

    case types.DELETE_CERTIFICATION_SUCCESS:
      return {
        ...state,
        // loading: false,
        errorMsg: '',
        data: {
          ...state.data,
          certifications: state.data.certifications.filter(
            (cert) => cert.id !== payload,
          ),
        },
      };

    case types.DELETE_CERTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    default:
      return state;
  }
}
