import Permissions from 'src/security/permissions';
import config from 'src/config';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import(
        'src/view/trainerCalendarPage/TrainerCalendarPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/messages',
    loader: () => import('src/view/chat/Chat'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  // {
  //   path: '/audit-logs',
  //   loader: () => import('src/view/auditLog/AuditLogPage'),
  //   permissionRequired: permissions.auditLogRead,
  // },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
  },

  {
    path: '/user-preference',
    loader: () =>
      import(
        'src/view/userPreference/list/UserPreferenceListPage'
      ),
    permissionRequired: permissions.userPreferenceRead,
    exact: true,
  },
  {
    path: '/user-preference/new',
    loader: () =>
      import(
        'src/view/userPreference/form/UserPreferenceFormPage'
      ),
    permissionRequired: permissions.userPreferenceCreate,
    exact: true,
  },
  {
    path: '/user-preference/importer',
    loader: () =>
      import(
        'src/view/userPreference/importer/UserPreferenceImporterPage'
      ),
    permissionRequired: permissions.userPreferenceImport,
    exact: true,
  },
  {
    path: '/user-preference/:id/edit',
    loader: () =>
      import(
        'src/view/userPreference/form/UserPreferenceFormPage'
      ),
    permissionRequired: permissions.userPreferenceEdit,
    exact: true,
  },
  {
    path: '/user-preference/:id',
    loader: () =>
      import(
        'src/view/userPreference/view/UserPreferenceViewPage'
      ),
    permissionRequired: permissions.userPreferenceRead,
    exact: true,
  },

  {
    path: '/admin-settings',
    loader: () =>
      import(
        'src/view/adminSettings/list/AdminSettingsListPage'
      ),
    permissionRequired: permissions.adminSettingsRead,
    exact: true,
  },
  {
    path: '/admin-settings/new',
    loader: () =>
      import(
        'src/view/adminSettings/form/AdminSettingsFormPage'
      ),
    permissionRequired: permissions.adminSettingsCreate,
    exact: true,
  },
  {
    path: '/admin-settings/importer',
    loader: () =>
      import(
        'src/view/adminSettings/importer/AdminSettingsImporterPage'
      ),
    permissionRequired: permissions.adminSettingsImport,
    exact: true,
  },
  {
    path: '/admin-settings/:id/edit',
    loader: () =>
      import(
        'src/view/adminSettings/form/AdminSettingsFormPage'
      ),
    permissionRequired: permissions.adminSettingsEdit,
    exact: true,
  },
  {
    path: '/admin-settings/:id',
    loader: () =>
      import(
        'src/view/adminSettings/view/AdminSettingsViewPage'
      ),
    permissionRequired: permissions.adminSettingsRead,
    exact: true,
  },

  {
    path: '/client-settings',
    loader: () =>
      import(
        'src/view/clientSettings/list/ClientSettingsListPage'
      ),
    permissionRequired: permissions.clientSettingsRead,
    exact: true,
  },
  {
    path: '/my-trainers',
    loader: () =>
      import(
        'src/view/clientSettings/list/ClientSettingsListPage'
      ),
    permissionRequired: permissions.clientSettingsClient,
    exact: true,
  },
  {
    path: '/client-settings/new',
    loader: () =>
      import(
        'src/view/clientSettings/form/ClientSettingsFormPage'
      ),
    permissionRequired: permissions.clientSettingsCreate,
    exact: true,
  },
  {
    path: '/client-settings/importer',
    loader: () =>
      import(
        'src/view/clientSettings/importer/ClientSettingsImporterPage'
      ),
    permissionRequired: permissions.clientSettingsImport,
    exact: true,
  },
  {
    path: '/client-settings/:id/edit',
    loader: () =>
      import(
        'src/view/clientSettings/form/ClientSettingsFormPage'
      ),
    permissionRequired: permissions.clientSettingsEdit,
    exact: true,
  },
  {
    path: '/client-settings/:id/details',
    loader: () =>
      import(
        'src/view/clientSettings/view/ClientSettingsViewPage'
      ),
    permissionRequired: permissions.clientSettingsRead,
    exact: true,
  },
  {
    path: '/client-settings/:id/questionnaire',
    loader: () =>
      import(
        'src/view/clientSettings/view/ClientSettingsViewPageQuestionnaire'
      ),
    permissionRequired: permissions.clientSettingsRead,
    exact: true,
  },
  {
    path: '/client-settings/:id/purchases',
    loader: () =>
      import(
        'src/view/clientSettings/view/ClientSettingsViewPagePurchase'
      ),
    permissionRequired: permissions.clientSettingsRead,
    exact: true,
  },

  {
    path: '/trainer-transfer',
    loader: () =>
      import(
        'src/view/trainerTransfer/list/TrainerTransferListPage'
      ),
    permissionRequired: permissions.trainerTransferRead,
    exact: true,
  },
  {
    path: '/trainer-transfer/new',
    loader: () =>
      import(
        'src/view/trainerTransfer/form/TrainerTransferFormPage'
      ),
    permissionRequired: permissions.trainerTransferCreate,
    exact: true,
  },
  {
    path: '/trainer-transfer/importer',
    loader: () =>
      import(
        'src/view/trainerTransfer/importer/TrainerTransferImporterPage'
      ),
    permissionRequired: permissions.trainerTransferImport,
    exact: true,
  },
  {
    path: '/trainer-transfer/:id/edit',
    loader: () =>
      import(
        'src/view/trainerTransfer/form/TrainerTransferFormPage'
      ),
    permissionRequired: permissions.trainerTransferEdit,
    exact: true,
  },
  {
    path: '/trainer-transfer/:id',
    loader: () =>
      import(
        'src/view/trainerTransfer/view/TrainerTransferViewPage'
      ),
    permissionRequired: permissions.trainerTransferRead,
    exact: true,
  },
  {
    path: '/trainer-public-profile',
    loader: () =>
      import(
        'src/view/trainerPublicProfile/TrainerPublicProfile'
      ),
    permissionRequired:
      permissions.trainerPublicProfileRead,
    exact: true,
  },
  {
    path: '/subscription-plan',
    loader: () =>
      import(
        'src/view/subscriptionPlan/list/SubscriptionPlanListPage'
      ),
    permissionRequired: permissions.subscriptionPlanRead,
    exact: true,
  },
  {
    path: '/admin-pricing/new',
    loader: () =>
      import(
        'src/view/subscriptionPlan/form/SubscriptionPlanFormPage'
      ),
    permissionRequired: permissions.subscriptionPlanCreate,
    exact: true,
  },
  {
    path: '/admin-coupons',
    loader: () =>
      import('src/view/adminPricing/AdminCoupons'),
    exact: true,
  },
  {
    path: '/subscription-plan/importer',
    loader: () =>
      import(
        'src/view/subscriptionPlan/importer/SubscriptionPlanImporterPage'
      ),
    permissionRequired: permissions.subscriptionPlanImport,
    exact: true,
  },
  {
    path: '/subscription-plan/:id/edit',
    loader: () =>
      import(
        'src/view/subscriptionPlan/form/SubscriptionPlanFormPage'
      ),
    permissionRequired: permissions.subscriptionPlanEdit,
    exact: true,
  },
  {
    path: '/subscription-plan/:id',
    loader: () =>
      import(
        'src/view/subscriptionPlan/view/SubscriptionPlanViewPage'
      ),
    permissionRequired: permissions.subscriptionPlanRead,
    exact: true,
  },

  {
    path: '/subscription',
    loader: () =>
      import(
        'src/view/subscription/list/SubscriptionListPage'
      ),
    permissionRequired: permissions.subscriptionRead,
    exact: true,
  },

  {
    path: '/finance/admin',
    loader: () =>
      import(
        'src/view/subscription/view/SubscriptionAdminPurchasesPage'
      ),
    exact: true,
  },

  {
    path: '/finance/admin/subscription',
    loader: () =>
      import(
        'src/view/subscription/view/SubscriptionAdminSubscriptionsPage'
      ),
    exact: true,
  },

  {
    path: '/subscription/my-earnings',
    loader: () =>
      import(
        'src/view/subscription/view/SubscriptionMyEarningsPage'
      ),
    exact: true,
  },

  {
    path: '/subscription/withdrawals',
    loader: () =>
      import(
        'src/view/subscription/view/SubscriptionWithdrawalsPage'
      ),
    exact: true,
  },

  {
    path: '/subscription/prices',
    loader: () =>
      import(
        'src/view/subscription/view/SubscriptionPricesPage'
      ),
    exact: true,
  },
  {
    path: '/subscription/new',
    loader: () =>
      import(
        'src/view/subscription/form/SubscriptionFormPage'
      ),
    permissionRequired: permissions.subscriptionCreate,
    exact: true,
  },
  {
    path: '/subscription/importer',
    loader: () =>
      import(
        'src/view/subscription/importer/SubscriptionImporterPage'
      ),
    permissionRequired: permissions.subscriptionImport,
    exact: true,
  },
  {
    path: '/subscription/:id/edit',
    loader: () =>
      import(
        'src/view/subscription/form/SubscriptionFormPage'
      ),
    permissionRequired: permissions.subscriptionEdit,
    exact: true,
  },
  {
    path: '/subscription/:id',
    loader: () =>
      import(
        'src/view/subscription/view/SubscriptionViewPage'
      ),
    permissionRequired: permissions.subscriptionRead,
    exact: true,
  },

  {
    path: '/payment',
    loader: () =>
      import('src/view/payment/list/PaymentListPage'),
    permissionRequired: permissions.paymentRead,
    exact: true,
  },
  {
    path: '/payment/new',
    loader: () =>
      import('src/view/payment/form/PaymentFormPage'),
    permissionRequired: permissions.paymentCreate,
    exact: true,
  },
  {
    path: '/payment/importer',
    loader: () =>
      import(
        'src/view/payment/importer/PaymentImporterPage'
      ),
    permissionRequired: permissions.paymentImport,
    exact: true,
  },
  {
    path: '/payment/:id/edit',
    loader: () =>
      import('src/view/payment/form/PaymentFormPage'),
    permissionRequired: permissions.paymentEdit,
    exact: true,
  },
  {
    path: '/payment/:id',
    loader: () =>
      import('src/view/payment/view/PaymentViewPage'),
    permissionRequired: permissions.paymentRead,
    exact: true,
  },

  {
    path: '/trainer-settings',
    loader: () =>
      import(
        'src/view/trainerSettings/list/TrainerSettingsListPage'
      ),
    permissionRequired: permissions.trainerSettingsRead,
    exact: true,
  },
  {
    path: '/trainer-settings/new',
    loader: () =>
      import(
        'src/view/trainerSettings/form/TrainerSettingsFormPage'
      ),
    permissionRequired: permissions.trainerSettingsCreate,
    exact: true,
  },
  {
    path: '/trainer-settings/importer',
    loader: () =>
      import(
        'src/view/trainerSettings/importer/TrainerSettingsImporterPage'
      ),
    permissionRequired: permissions.trainerSettingsImport,
    exact: true,
  },
  {
    path: '/trainer-settings/:id/edit',
    loader: () =>
      import(
        'src/view/trainerSettings/form/TrainerSettingsFormPage'
      ),
    permissionRequired: permissions.trainerSettingsEdit,
    exact: true,
  },
  {
    path: '/trainer-settings/:id',
    loader: () =>
      import(
        'src/view/trainerSettings/view/TrainerSettingsViewPage'
      ),
    permissionRequired: permissions.trainerSettingsRead,
    exact: true,
  },
  {
    path: '/video-conference',
    loader: () =>
      import(
        'src/view/videoConferencing/VideoConferencing'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/video-category',
    loader: () =>
      import(
        'src/view/videoCategory/list/VideoCategoryListPage'
      ),
    permissionRequired: permissions.videoCategoryRead,
    exact: true,
  },
  {
    path: '/video-category/new',
    loader: () =>
      import(
        'src/view/videoCategory/form/VideoCategoryFormPage'
      ),
    permissionRequired: permissions.videoCategoryCreate,
    exact: true,
  },
  {
    path: '/video-category/importer',
    loader: () =>
      import(
        'src/view/videoCategory/importer/VideoCategoryImporterPage'
      ),
    permissionRequired: permissions.videoCategoryImport,
    exact: true,
  },
  {
    path: '/video-category/:id/edit',
    loader: () =>
      import(
        'src/view/videoCategory/form/VideoCategoryFormPage'
      ),
    permissionRequired: permissions.videoCategoryEdit,
    exact: true,
  },
  {
    path: '/video-category/:id',
    loader: () =>
      import(
        'src/view/videoCategory/view/VideoCategoryViewPage'
      ),
    permissionRequired: permissions.videoCategoryRead,
    exact: true,
  },

  {
    path: '/video-library',
    loader: () =>
      import(
        'src/view/videoLibrary/list/VideoLibraryListPage'
      ),
    permissionRequired: permissions.videoLibraryRead,
    exact: true,
  },
  {
    path: '/video-library/new',
    loader: () =>
      import(
        'src/view/videoLibrary/form/VideoLibraryFormPage'
      ),
    permissionRequired: permissions.videoLibraryCreate,
    exact: true,
  },
  {
    path: '/video-library/importer',
    loader: () =>
      import(
        'src/view/videoLibrary/importer/VideoLibraryImporterPage'
      ),
    permissionRequired: permissions.videoLibraryImport,
    exact: true,
  },
  {
    path: '/video-library/:id/edit',
    loader: () =>
      import(
        'src/view/videoLibrary/form/VideoLibraryFormPage'
      ),
    permissionRequired: permissions.videoLibraryEdit,
    exact: true,
  },
  {
    path: '/video-library/:id',
    loader: () =>
      import(
        'src/view/videoLibrary/view/VideoLibraryViewPage'
      ),
    permissionRequired: permissions.videoLibraryRead,
    exact: true,
  },

  {
    path: '/session-type',
    loader: () =>
      import(
        'src/view/sessionType/list/SessionTypeListPage'
      ),
    permissionRequired: permissions.sessionTypeRead,
    exact: true,
  },
  {
    path: '/session-type/new',
    loader: () =>
      import(
        'src/view/sessionType/form/SessionTypeFormPage'
      ),
    permissionRequired: permissions.sessionTypeCreate,
    exact: true,
  },
  {
    path: '/session-type/importer',
    loader: () =>
      import(
        'src/view/sessionType/importer/SessionTypeImporterPage'
      ),
    permissionRequired: permissions.sessionTypeImport,
    exact: true,
  },
  {
    path: '/session-type/:id/edit',
    loader: () =>
      import(
        'src/view/sessionType/form/SessionTypeFormPage'
      ),
    permissionRequired: permissions.sessionTypeEdit,
    exact: true,
  },
  {
    path: '/session-type/:id',
    loader: () =>
      import(
        'src/view/sessionType/view/SessionTypeViewPage'
      ),
    permissionRequired: permissions.sessionTypeRead,
    exact: true,
  },

  {
    path: '/trainer-session-plan',
    loader: () =>
      import(
        'src/view/trainerSessionPlan/list/TrainerSessionPlanListPage'
      ),
    permissionRequired: permissions.trainerSessionPlanRead,
    exact: true,
  },
  {
    path: '/trainer-session-plan/new',
    loader: () =>
      import(
        'src/view/trainerSessionPlan/form/TrainerSessionPlanFormPage'
      ),
    permissionRequired:
      permissions.trainerSessionPlanCreate,
    exact: true,
  },
  {
    path: '/trainer-session-plan/importer',
    loader: () =>
      import(
        'src/view/trainerSessionPlan/importer/TrainerSessionPlanImporterPage'
      ),
    permissionRequired:
      permissions.trainerSessionPlanImport,
    exact: true,
  },
  {
    path: '/trainer-session-plan/:id/edit',
    loader: () =>
      import(
        'src/view/trainerSessionPlan/form/TrainerSessionPlanFormPage'
      ),
    permissionRequired: permissions.trainerSessionPlanEdit,
    exact: true,
  },
  {
    path: '/trainer-session-plan/:id',
    loader: () =>
      import(
        'src/view/trainerSessionPlan/view/TrainerSessionPlanViewPage'
      ),
    permissionRequired: permissions.trainerSessionPlanRead,
    exact: true,
  },

  {
    path: '/trainer-free-slot',
    loader: () =>
      import(
        'src/view/trainerFreeSlot/list/TrainerFreeSlotListPage'
      ),
    permissionRequired: permissions.trainerFreeSlotRead,
    exact: true,
  },
  {
    path: '/trainer-free-slot/new',
    loader: () =>
      import(
        'src/view/trainerFreeSlot/form/TrainerFreeSlotFormPage'
      ),
    permissionRequired: permissions.trainerFreeSlotCreate,
    exact: true,
  },
  {
    path: '/trainer-free-slot/importer',
    loader: () =>
      import(
        'src/view/trainerFreeSlot/importer/TrainerFreeSlotImporterPage'
      ),
    permissionRequired: permissions.trainerFreeSlotImport,
    exact: true,
  },
  {
    path: '/trainer-free-slot/:id/edit',
    loader: () =>
      import(
        'src/view/trainerFreeSlot/form/TrainerFreeSlotFormPage'
      ),
    permissionRequired: permissions.trainerFreeSlotEdit,
    exact: true,
  },
  {
    path: '/trainer-free-slot/:id',
    loader: () =>
      import(
        'src/view/trainerFreeSlot/view/TrainerFreeSlotViewPage'
      ),
    permissionRequired: permissions.trainerFreeSlotRead,
    exact: true,
  },
  {
    path: '/trainer-calendar/:id?',
    loader: () =>
      import(
        'src/view/trainerCalendarPage/TrainerCalendarPage'
      ),
    permissionRequired:
      permissions.trainerCaledarSessionRead,
    exact: true,
  },
  {
    path: '/trainer-calendar-session',
    loader: () =>
      import(
        'src/view/trainerCalendarSession/list/TrainerCalendarSessionListPage'
      ),
    permissionRequired:
      permissions.trainerCaledarSessionRead,
    exact: true,
  },
  {
    path: '/trainer-calendar-session/new',
    loader: () =>
      import(
        'src/view/trainerCalendarSession/form/TrainerCalendarSessionFormPage'
      ),
    permissionRequired:
      permissions.trainerCaledarSessionCreate,
    exact: true,
  },
  {
    path: '/trainer-calendar-session/importer',
    loader: () =>
      import(
        'src/view/trainerCalendarSession/importer/TrainerCalendarSessionImporterPage'
      ),
    permissionRequired:
      permissions.trainerCaledarSessionImport,
    exact: true,
  },
  {
    path: '/trainer-calendar-session/:id/edit',
    loader: () =>
      import(
        'src/view/trainerCalendarSession/form/TrainerCalendarSessionFormPage'
      ),
    permissionRequired:
      permissions.trainerCaledarSessionEdit,
    exact: true,
  },
  {
    path: '/trainer-calendar-session/:id',
    loader: () =>
      import(
        'src/view/trainerCalendarSession/view/TrainerCalendarSessionViewPage'
      ),
    permissionRequired:
      permissions.trainerCaledarSessionRead,
    exact: true,
  },
  {
    path: '/admin-pricing',
    loader: () =>
      import('src/view/adminPricing/AdminPricing'),
    permissionRequired: permissions.adminPricing,
    exact: true,
  },
  {
    path: '/admin-trainers',
    loader: () =>
      import('src/view/adminTrainers/AdminTrainers'),
    exact: true,
  },
  {
    path: '/admin-clients',
    loader: () =>
      import('src/view/adminClients/AdminClients'),
    exact: true,
  },
  {
    path: '/client-profile/:page?',
    loader: () =>
      import('src/view/clientProfile/ClientProfile'),
    permissionRequired: permissions.clientSettingsClient,
    exact: true,
  },
  {
    path: '/onboarding/:id?',
    loader: () =>
      import('src/view/onBoarding/layouts/OnBoardingPage'),
    // permissionRequired: permissions.onBoarding,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin/:redirect?',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup/:type?',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/token/:token',
    loader: () => import('src/view/auth/TokenLoginPage'),
  },
  {
    path: '/auth/confirm-email',
    loader: () => import('src/view/auth/EmailConfirmPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

const externalRoutes = [
  {
    path: '/clients/trainers/:id',
    loader: () =>
      import(
        'src/view/externalPages/TrainerProfile/TrainerProfile'
      ),
  },
  {
    path: '/clients/trainers/',
    loader: () =>
      import(
        'src/view/externalPages/AllTrainersPage/AllTrainersPage'
      ),
  },
  {
    path: '/trainers/',
    loader: () =>
      import(
        'src/view/externalPages/ForTrainersPage/ForTrainersPage'
      ),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
  externalRoutes,
};
