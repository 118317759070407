import * as types from './actionTypes';

const INITIAL_PAGE_SIZE = 40;

const DefaultState = {
  loading: false,
  errorMsg: '',
  couponsList: [],
  count: 0,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
};
export default function couponsReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.ADMIN_COUPONS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.ADMIN_COUPONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        count: payload.count,
        couponsList: payload.data || null,
      };

    case types.ADMIN_COUPONS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.ADMIN_COUPONS_DELETE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };
    
    case types.ADMIN_COUPONS_DELETE_SUCCESS:
      const currentList: any[] = [...state.couponsList];
      let currentCouponIndex: number = currentList.findIndex((coupon: any) => coupon.id === payload.id);
      if (currentCouponIndex !== -1) {
        currentList[currentCouponIndex] = {
          ...currentList[currentCouponIndex],
          active: false,
        }
      }
      return {
        ...state,
        couponsList: currentList,
        loading: false,
      };

    case types.ADMIN_COUPONS_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.ADMIN_COUPONS_CREATE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.ADMIN_COUPONS_CREATE_SUCCESS:
      const newList = [payload?.coupon, ...state.couponsList];
      return {
        ...state,
        couponsList: newList,
        loading: false,
      };

    case types.ADMIN_COUPONS_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };
    default:
      return state;
  }
}
