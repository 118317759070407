import React from 'react';
import './styles/messageNotification.scss';

interface MessageNotificationProps {
  count?: number;
}
const MessageNotification = (props: MessageNotificationProps) => {
  return (
    <div className={'message_notify'}>
      {/*<span>{props.count}</span>*/}
    </div>
  );
};

export default MessageNotification;
