import * as types from './actionTypes';

const INITIAL_PAGE_SIZE = 10;

const DefaultState = {
  loading: false,
  errorMsg: '',
  earnings: [],
  count: 0,
  total: 0,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
};
export default function adminSubscriptionReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.LOAD_ADMIN_SUBSCRIPTION_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.LOAD_ADMIN_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        count: payload.count,
        total: payload.total,
        earnings: payload.rows || null,
      };

    case types.LOAD_ADMIN_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.LOAD_ADMIN_SUBSCRIPTION_PAGINATION_CHANGED:
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };

    default:
      return state;
  }
}
