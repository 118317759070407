import actions from 'src/modules/videoLibrary/trainers/videoLibraryTrainersActions';

const initialData = {
  loading: false,
  trainers: [],
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_TRAINERS_STARTED) {
    return {
      ...state,
      trainers: [],
      loading: true,
    };
  }

  if (type === actions.FETCH_TRAINERS_SUCCESS) {
    return {
      ...state,
      trainers: payload,
      loading: false,
    };
  }

  if (type === actions.FETCH_TRAINERS_ERROR) {
    return {
      ...state,
      trainers: [],
      loading: false,
    };
  }

  return state;
};
