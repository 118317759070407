import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import authActions from 'src/modules/auth/authActions';
import authSelectors from 'src/modules/auth/authSelectors';
import HeaderWrapper from 'src/view/layout/styles/HeaderWrapper';
import Avatar from 'src/view/shared/Avatar';
import config from 'src/config';
import MenuMobile from './MenuMobile';
import useBreakpoint from '../../utils/useBreakpoint';
import PermissionChecker from "../../modules/auth/permissionChecker";
import {Client as ConversationsClient} from '@twilio/conversations';
import { Helmet } from "react-helmet";

function Header() {
  const dispatch = useDispatch();

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const isTrainer = new PermissionChecker(currentTenant, currentUser).validateIsTrainer();
  const isAdmin = new PermissionChecker(currentTenant, currentUser).validateIsAdmin();
  const doSignout = async () => {
    const conversationsClient = (window as any).ConversationsClient as ConversationsClient;
    if (conversationsClient) {
      await conversationsClient.shutdown();
    }

    dispatch(authActions.doSignout())
  };
  const isMobile = useBreakpoint() === 'mobile';

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <HeaderWrapper
        className={`navbar navbar-light ${
          isMobile ? 'mobile' : ''
        }`}
      >
        {isMobile ? (
          <img
            src="/images/logo/the-trainory-logo-black.svg"
            alt=""
            height={'23px'}
          />
        ) : null}
        <div className={'header-options'}>
          <div className="dropdown">
            <span
              className={`user-dropdown ${
                isMobile ? 'mobile' : ''
              }`}
              data-toggle="dropdown"
            >
              <div className="user-dropdown-content">
                <span className="user-dropdown-avatar">
                 { isAdmin ? (
                     <Avatar
                         size="small"
                         src={"/images/publicProfile/person-admin.png" }
                         alt="avatar"
                     />
                 ) : (
                     <Avatar
                         size="small"
                         src={
                           isTrainer ? currentUser.profilePictureUrl || undefined
                             : currentUser.avatars[0]?.downloadUrl
                             ? currentUser.avatars[0]?.downloadUrl
                             : ''
                         }
                         alt="avatar"
                     />
                 )
                 }
                </span>
                <span className="user-dropdown-text">
                  {[
                    'multi',
                    'multi-with-subdomain',
                  ].includes(config.tenantMode) && (
                    <span className="user-dropdown-text-tenant">
                      {currentTenant && currentTenant.name}
                    </span>
                  )}
                </span>
              </div>
            </span>
            <div className="dropdown-menu dropdown-menu-right log-out">
              <button
                onClick={doSignout}
                className="dropdown-item custom-logout"
                type="button"
              >
                {'Log Out'}
              </button>
            </div>
          </div>
        </div>
      </HeaderWrapper>
      {isMobile ? <MenuMobile /> : null}
    </>
  );
}

export default Header;
