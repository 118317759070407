export const LOAD_VIDEO_SUBSCRIPTION_PLAN_LOADING =
  'LOAD_VIDEO_SUBSCRIPTION_PLAN_LOADING';
export const LOAD_VIDEO_SUBSCRIPTION_PLAN_SUCCESS =
  'LOAD_VIDEO_SUBSCRIPTION_PLAN_SUCCESS';
export const LOAD_VIDEO_SUBSCRIPTION_PLAN_FAIL =
  'LOAD_VIDEO_SUBSCRIPTION_PLAN_FAIL';

export const SAVE_VIDEO_SUBSCRIPTION_PLAN_LOADING =
  'SAVE_VIDEO_SUBSCRIPTION_PLAN_LOADING';
export const SAVE_VIDEO_SUBSCRIPTION_PLAN_SUCCESS =
  'SAVE_VIDEO_SUBSCRIPTION_PLAN_SUCCESS';
export const SAVE_VIDEO_SUBSCRIPTION_PLAN_FAIL =
  'SAVE_VIDEO_SUBSCRIPTION_PLAN_FAIL';
