export const GET_CLIENT_PROFILE_LOADING =
  'GET_CLIENT_PROFILE_LOADING';
export const GET_CLIENT_PROFILE_SUCCESS =
  'GET_CLIENT_PROFILE_SUCCESS';
export const GET_CLIENT_PROFILE_FAIL =
  'GET_CLIENT_PROFILE_FAIL';

export const UPDATE_CLIENT_PROFILE_LOADING =
  'UPDATE_CLIENT_PROFILE_LOADING';
export const UPDATE_CLIENT_PROFILE_SUCCESS =
  'UPDATE_CLIENT_PROFILE_SUCCESS';
export const UPDATE_CLIENT_PROFILE_FAIL =
  'UPDATE_CLIENT_PROFILE_FAIL';
