import actions from 'src/modules/contactUs/contactUsActions';

const initialData = {
  response: null,
  loading: false,
  errorMessage: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.CONTACT_US_SEND_START) {
    return {
      ...state,
      errorMessage: null,
      loading: true,
    };
  }

  if (type === actions.CONTACT_US_SEND_SUCCESS) {
    return {
      ...state,
      response: payload.res || null,
      errorMessage: null,
      loading: false,
    };
  }

  if (type === actions.CONTACT_US_SEND_ERROR) {
    return {
      ...state,
      response: null,
      errorMessage: payload || null,
      loading: false,
    };
  }

  return state;
};
