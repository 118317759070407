import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class PlanService {
  static async fetchCheckoutSessionId(
    subscriptionPlanId: string,
    stripePriceId: string,
    redirectUrl: string,
  ) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/checkout`,
      {
        subscriptionPlanId,
        stripePriceId,
        redirectUrl,
      },
    );

    return response.data.id;
  }

  static async fetchAccountLink(redirectPath: string) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.post(
      `/tenant/${tenantId}/account/stripe/checkout`,
      { redirectPath }
    );
    return response.data.url;
  }

  static async fetchSessionCheckoutSessionId(
    trainerCalendarSessionId,
  ) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/session/stripe/checkout`,
      {
        trainerCalendarSessionId,
      },
    );

    return response.data.id;
  }

  static async fetchPortalUrl() {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/plan/stripe/portal`,
    );

    return response.data.url;
  }
}
