import listActions from 'src/modules/subscriptionPlan/list/subscriptionPlanListActions';
import SubscriptionPlanService from 'src/modules/subscriptionPlan/subscriptionPlanService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'SUBSCRIPTIONPLAN_DESTROY';

const subscriptionPlanDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  CANCEL_SUBSCRIPTION_STARTED: `${prefix}_CANCEL_SUBSCRIPTION_STARTED`,
  CANCEL_SUBSCRIPTION_SUCCESS: `${prefix}_CANCEL_SUBSCRIPTION_SUCCESS`,
  CANCEL_SUBSCRIPTION_ERROR: `${prefix}_CANCEL_SUBSCRIPTION_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: subscriptionPlanDestroyActions.DESTROY_STARTED,
      });

      await SubscriptionPlanService.destroyAll([id]);

      dispatch({
        type: subscriptionPlanDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.subscriptionPlan.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/subscription-plan');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: subscriptionPlanDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: subscriptionPlanDestroyActions.DESTROY_ALL_STARTED,
      });

      await SubscriptionPlanService.destroyAll(ids);

      dispatch({
        type: subscriptionPlanDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n(
          'entities.subscriptionPlan.destroyAll.success',
        ),
      );

      getHistory().push('/subscription-plan');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: subscriptionPlanDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },

  doCancelSubscription: (id) => async (dispatch) => {
    try {
      dispatch({
        type: subscriptionPlanDestroyActions.CANCEL_SUBSCRIPTION_STARTED,
      });

      await SubscriptionPlanService.cancelSubscription(id);

      dispatch({
        type: subscriptionPlanDestroyActions.CANCEL_SUBSCRIPTION_SUCCESS,
      });

      Message.success('Subscription Canceled');

      getHistory().push('/settings');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subscriptionPlanDestroyActions.CANCEL_SUBSCRIPTION_ERROR,
      });
    }
  },

};

export default subscriptionPlanDestroyActions;
