import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import trainerCalendarSessionEnumerators from 'src/modules/trainerCalendarSession/trainerCalendarSessionEnumerators';

export default [
  {
    name: 'trainerId',
    label: i18n(
      'entities.trainerCaledarSession.fields.trainerId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.trainerCaledarSession.fields.trainerId',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startTime',
    label: i18n(
      'entities.trainerCaledarSession.fields.startTime',
    ),
    schema: schemas.date(
      i18n(
        'entities.trainerCaledarSession.fields.startTime',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endTime',
    label: i18n(
      'entities.trainerCaledarSession.fields.endTime',
    ),
    schema: schemas.date(
      i18n('entities.trainerCaledarSession.fields.endTime'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startTimeWithBuffer',
    label: i18n(
      'entities.trainerCaledarSession.fields.startTimeWithBuffer',
    ),
    schema: schemas.date(
      i18n(
        'entities.trainerCaledarSession.fields.startTimeWithBuffer',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endTimeWithBuffer',
    label: i18n(
      'entities.trainerCaledarSession.fields.endTimeWithBuffer',
    ),
    schema: schemas.date(
      i18n(
        'entities.trainerCaledarSession.fields.endTimeWithBuffer',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'sessionPlanId',
    label: i18n(
      'entities.trainerCaledarSession.fields.sessionPlanId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.trainerCaledarSession.fields.sessionPlanId',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'paymentId',
    label: i18n(
      'entities.trainerCaledarSession.fields.paymentId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.trainerCaledarSession.fields.paymentId',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'cost',
    label: i18n(
      'entities.trainerCaledarSession.fields.cost',
    ),
    schema: schemas.decimal(
      i18n('entities.trainerCaledarSession.fields.cost'),
      {
        required: true,
        scale: 2,
        min: 0,
      },
    ),
  },
  {
    name: 'discount',
    label: i18n(
      'entities.trainerCaledarSession.fields.discount',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.trainerCaledarSession.fields.discount',
      ),
      {
        required: true,
        scale: 2,
        min: 0,
      },
    ),
  },
  {
    name: 'conferenceUrl',
    label: i18n(
      'entities.trainerCaledarSession.fields.conferenceUrl',
    ),
    schema: schemas.string(
      i18n(
        'entities.trainerCaledarSession.fields.conferenceUrl',
      ),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n(
      'entities.trainerCaledarSession.fields.status',
    ),
    schema: schemas.enumerator(
      i18n('entities.trainerCaledarSession.fields.status'),
      {
        required: true,
        options: trainerCalendarSessionEnumerators.status,
      },
    ),
  },
  {
    name: 'trainerTransferId',
    label: i18n(
      'entities.trainerCaledarSession.fields.trainerTransferId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.trainerCaledarSession.fields.trainerTransferId',
      ),
      {},
    ),
  },
];
