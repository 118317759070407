import { useState, useEffect } from 'react';
import throttle from 'lodash';
import * as constants from './constants';

const getDeviceConfig = (width: number) => {
  if (width < constants.MOBILE_BREAKPOINT) {
    return 'mobile';
  } else {
    return 'desktop';
  }
};

const useBreakpoint = () => {
  const [breakPoint, setBreakPoint] = useState(() =>
    getDeviceConfig(window.innerWidth),
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBreakPoint(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () =>
      window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakPoint;
};
export default useBreakpoint;
