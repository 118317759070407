import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'trainerId',
    label: i18n('entities.videoLibrary.fields.trainerId'),
    schema: schemas.relationToOne(
      i18n('entities.videoLibrary.fields.trainerId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'categoryId',
    label: i18n('entities.videoLibrary.fields.categoryId'),
    schema: schemas.relationToOne(
      i18n('entities.videoLibrary.fields.categoryId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.videoLibrary.fields.name'),
    schema: schemas.string(
      i18n('entities.videoLibrary.fields.name'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'deescription',
    label: i18n(
      'entities.videoLibrary.fields.deescription',
    ),
    schema: schemas.string(
      i18n('entities.videoLibrary.fields.deescription'),
      {},
    ),
  },
  {
    name: 'file',
    label: i18n('entities.videoLibrary.fields.file'),
    schema: schemas.files(
      i18n('entities.videoLibrary.fields.file'),
      {
        required: true,
      },
    ),
  },
];
