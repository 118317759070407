import * as types from './actionTypes';

const INITIAL_PAGE_SIZE = 10;

const DefaultState = {
  loading: false,
  errorMsg: '',
  payments: [],
  count: 0,
  total: 0,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
};
export default function myPaymentReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.FINANCE_MY_PAYMENTS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.FINANCE_MY_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        count: payload.count,
        payments: payload.rows || null,
        total: payload.total,
      };

    case types.FINANCE_MY_PAYMENTS_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.FINANCE_MY_PAYMENTS_PAGINATION_CHANGED:
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };

    default:
      return state;
  }
}
