import React, { useState } from 'react';
import menus from '../menus';
import PermissionChecker from '../../modules/auth/permissionChecker';
import { useSelector } from 'react-redux';
import authSelectors from '../../modules/auth/authSelectors';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import './styles/menuMobile.scss';

const MenuMobile = () => {
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );

  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  );

  const matchRoute = useRouteMatch();
  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  const selectedKeys = () => {
    const url = matchRoute.url;

    const match = menus.find((option) => {
      return (
        url === option.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      return [match.path];
    }

    return [];
  };

  const [menuOpened, setMenuOpened] = useState(false);
  const isMenuOpened = () => {
    setMenuOpened(!menuOpened);
  };

  return (
    <>
      <div
        className={`menu-mobile ${
          menuOpened ? 'opened' : 'closed'
        }`}
      >
        <ul className="menu-ul ">
          {menus
            .filter((menu) =>
              match(menu.permissionRequired),
            )
            .map((menu, index) => (
              <li
                key={menu.path}
                className={`menu-li text-nowrap ${
                  selectedKeys().includes(menu.path)
                    ? 'active'
                    : ''
                }`}
              >
                <Link to={menu.path}>
                  <img
                    className={`${
                      selectedKeys().includes(menu.path)
                        ? 'active'
                        : ''
                    }`}
                    src={
                      '/images/icons/' + menu.icon + '.svg'
                    }
                    alt=""
                  />
                  <span className={'menu-label-name'}>
                    {menu.label}
                    {/*{menu.label === 'Messages' ? (*/}
                    {/*  <MessageNotification />*/}
                    {/*) : (*/}
                    {/*  ''*/}
                    {/*)}*/}
                  </span>
                </Link>
              </li>
            ))}
        </ul>
        <img
          className={'btn-mobile-close'}
          src={
            menuOpened
              ? '/images/icons/close.svg'
              : '/images/icons/burger.svg'
          }
          alt=""
          onClick={() => isMenuOpened()}
        />
      </div>
    </>
  );
};

export default MenuMobile;
