import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class TrainerCalendarSessionService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/trainer-caledar-session/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/trainer-caledar-session`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-caledar-session`,
      body,
    );

    return response.data;
  }

  static async createGroupAppointment(data) {
    const body = { data };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-calendar-session/trainer-group-session`,
      body,
    );

    return response.data;
  }
  
  static async createPersonalAppointment(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-calendar-session/individual-session`,
      body,
    );

    return response.data;
  }

  static async joinGroupSession(sessionID: string, clientId: string | null = null) {
    const body = {
      data : {
        trainerCalendarSessionId: sessionID
      } as any
    };

    if (clientId) {
      body.data.clientId = clientId;
    }

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-calendar-session/client-group-session`,
      body,
    );

    return response.data;
  }

  static async createUnavailable(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-calendar-session/unavailability`,
      body,
    );

    return response.data;
  }

  static async deleteAppointment(data) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/trainer-calendar-session`,
      {
        params: data,
      },
    );

    return response.data;
  }

  static async acceptAppointment(id: string) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-calendar-session/accept/${id}`,
    );
    return response.data;
  }

  static async cancelAppointment(id: string, message: string) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-calendar-session/cancel/${id}?message=${message}`,
    );
    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/trainer-caledar-session/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/trainer-caledar-session/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      showScheduled: true
    } as any;

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/trainer-calendar-session`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/trainer-caledar-session/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}
