import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import adminSettingsEnumerators from 'src/modules/adminSettings/adminSettingsEnumerators';

export default [
  {
    name: 'trainerId',
    label: i18n('entities.adminSettings.fields.trainerId'),
    schema: schemas.relationToOne(
      i18n('entities.adminSettings.fields.trainerId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'feeAmount',
    label: i18n('entities.adminSettings.fields.feeAmount'),
    schema: schemas.decimal(
      i18n('entities.adminSettings.fields.feeAmount'),
      {
        required: true,
        min: 0,
        scale: 2,
      },
    ),
  },
  {
    name: 'feeFrequency',
    label: i18n(
      'entities.adminSettings.fields.feeFrequency',
    ),
    schema: schemas.enumerator(
      i18n('entities.adminSettings.fields.feeFrequency'),
      {
        required: true,
        options: adminSettingsEnumerators.feeFrequency,
      },
    ),
  },
  {
    name: 'annualDiscount',
    label: i18n(
      'entities.adminSettings.fields.annualDiscount',
    ),
    schema: schemas.decimal(
      i18n('entities.adminSettings.fields.annualDiscount'),
      {
        min: 0,
        scale: 2,
      },
    ),
  },
];
