import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.adminSettings.fields.id'),
  },
  {
    name: 'trainerId',
    label: i18n('entities.adminSettings.fields.trainerId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'feeAmount',
    label: i18n('entities.adminSettings.fields.feeAmount'),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'feeFrequency',
    label: i18n(
      'entities.adminSettings.fields.feeFrequency',
    ),
  },
  {
    name: 'annualDiscount',
    label: i18n(
      'entities.adminSettings.fields.annualDiscount',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'createdAt',
    label: i18n('entities.adminSettings.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.adminSettings.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
