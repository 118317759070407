import list from 'src/modules/trainerSettings/list/trainerSettingsListReducers';
import form from 'src/modules/trainerSettings/form/trainerSettingsFormReducers';
import view from 'src/modules/trainerSettings/view/trainerSettingsViewReducers';
import destroy from 'src/modules/trainerSettings/destroy/trainerSettingsDestroyReducers';
import importerReducer from 'src/modules/trainerSettings/importer/trainerSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
