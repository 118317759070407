import VideoLibraryService from 'src/modules/videoLibrary/videoLibraryService';

const prefix = 'VIDEOLIBRARY_TRAINERS';

const videoLibraryTrainersActions = {
  FETCH_TRAINERS_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_TRAINERS_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_TRAINERS_ERROR: `${prefix}_FETCH_ERROR`,
  
  doFetch:
    () =>
    async (dispatch) => {
      try {
        dispatch({ type: videoLibraryTrainersActions.FETCH_TRAINERS_STARTED });

        const response = await VideoLibraryService.listVideoSubscribedTrainers();
        dispatch({
          type: videoLibraryTrainersActions.FETCH_TRAINERS_SUCCESS,
          payload: response.rows,
        });
      } catch (error) {
        dispatch({
          type: videoLibraryTrainersActions.FETCH_TRAINERS_ERROR,
          payload: 'There was an error loading trainers. Please make sure that you have a valid video subscription.'
        });
      }
    }
  }

export default videoLibraryTrainersActions;
