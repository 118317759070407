import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/trainerSessionPlan/importer/trainerSessionPlanImporterSelectors';
import TrainerSessionPlanService from 'src/modules/trainerSessionPlan/trainerSessionPlanService';
import fields from 'src/modules/trainerSessionPlan/importer/trainerSessionPlanImporterFields';
import { i18n } from 'src/i18n';

const trainerSessionPlanImporterActions = importerActions(
  'TRAINERSESSIONPLAN_IMPORTER',
  selectors,
  TrainerSessionPlanService.import,
  fields,
  i18n('entities.trainerSessionPlan.importer.fileName'),
);

export default trainerSessionPlanImporterActions;
