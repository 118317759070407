import list from 'src/modules/subscription/list/subscriptionListReducers';
import form from 'src/modules/subscription/form/subscriptionFormReducers';
import view from 'src/modules/subscription/view/subscriptionViewReducers';
import destroy from 'src/modules/subscription/destroy/subscriptionDestroyReducers';
import importerReducer from 'src/modules/subscription/importer/subscriptionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
