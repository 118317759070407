import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  list: [],
  total: '',
};

export default function myPayoutsReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.FINANCE_MY_PAYOUTS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.FINANCE_MY_PAYOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        list: payload.list,
        total: payload.total
      };

    case types.FINANCE_MY_PAYOUTS_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    default:
      return state;
  }
}
