import service from 'src/modules/liveVideo/liveVideoService';
import Errors from 'src/modules/shared/error/errors';

const liveVideoActions = {
  ROOM_FETCH_START: 'ROOM_FETCH_START',
  ROOM_FETCH_SUCCESS: 'ROOM_FETCH_SUCCESS',
  ROOM_FETCH_ERROR: 'ROOM_FETCH_ERROR',

  doRoomFetch: (name: string) => async (dispatch) => {
    try {
      dispatch({ type:liveVideoActions.ROOM_FETCH_START });
      const room = await service.fetchRoom(name);
      dispatch({ type:liveVideoActions.ROOM_FETCH_SUCCESS, payload:{room} })
    } catch (error) {
      Errors.showMessage(error);
      dispatch({ type: liveVideoActions.ROOM_FETCH_ERROR, payload: error });
    }
  },
};

export default liveVideoActions;
