import list from 'src/modules/userPreference/list/userPreferenceListReducers';
import form from 'src/modules/userPreference/form/userPreferenceFormReducers';
import view from 'src/modules/userPreference/view/userPreferenceViewReducers';
import destroy from 'src/modules/userPreference/destroy/userPreferenceDestroyReducers';
import importerReducer from 'src/modules/userPreference/importer/userPreferenceImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
