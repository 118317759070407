import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'trainerId',
    label: i18n(
      'entities.trainerSessionPlan.fields.trainerId',
    ),
    schema: schemas.relationToOne(
      i18n('entities.trainerSessionPlan.fields.trainerId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.trainerSessionPlan.fields.name'),
    schema: schemas.string(
      i18n('entities.trainerSessionPlan.fields.name'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'sessionTypeId',
    label: i18n(
      'entities.trainerSessionPlan.fields.sessionTypeId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.trainerSessionPlan.fields.sessionTypeId',
      ),
      {
        required: true,
      },
    ),
  },
  {
    name: 'costPerHour',
    label: i18n(
      'entities.trainerSessionPlan.fields.costPerHour',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.trainerSessionPlan.fields.costPerHour',
      ),
      {
        required: true,
        scale: 2,
        min: 0,
      },
    ),
  },
  {
    name: 'individualSession',
    label: i18n(
      'entities.trainerSessionPlan.fields.individualSession',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.trainerSessionPlan.fields.individualSession',
      ),
      {},
    ),
  },
  {
    name: 'duration',
    label: i18n(
      'entities.trainerSessionPlan.fields.duration',
    ),
    schema: schemas.integer(
      i18n('entities.trainerSessionPlan.fields.duration'),
      {
        required: true,
        min: 1,
      },
    ),
  },
];
