export const ADMIN_COUPONS_LIST_LOADING =
  'LOAD_ADMIN_COUPONS_LIST_LOADING';
export const ADMIN_COUPONS_LIST_SUCCESS =
  'LOAD_ADMIN_COUPONS_LIST_SUCCESS';
export const ADMIN_COUPONS_LIST_FAIL =
  'LOAD_ADMIN_COUPONS_LIST_FAIL';
export const ADMIN_COUPONS_LIST_PAGINATION_CHANGED =
  'ADMIN_COUPONS_LIST_PAGINATION_CHANGED';

export const ADMIN_COUPONS_DELETE_LOADING =
  'ADMIN_COUPONS_DELETE_LOADING';
export const ADMIN_COUPONS_DELETE_SUCCESS =
  'ADMIN_COUPONS_DELETE_SUCCESS';
export const ADMIN_COUPONS_DELETE_FAIL =
  'ADMIN_COUPONS_DELETE_FAIL';

export const ADMIN_COUPONS_CREATE_LOADING =
  'ADMIN_COUPONS_CREATE_LOADING';
export const ADMIN_COUPONS_CREATE_SUCCESS =
  'ADMIN_COUPONS_CREATE_SUCCESS';
export const ADMIN_COUPONS_CREATE_FAIL =
  'ADMIN_COUPONS_CREATE_FAIL';