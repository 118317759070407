import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  payload: {},
  errorMsg: ''
};
export default function adminReducer(
  state = DefaultState,
  {type, payload},
) {
  switch (type) {
    case types.DO_REFUND_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.DO_REFUND_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };

    case types.DO_REFUND_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    default:
      return state;
  }
}
