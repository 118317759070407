import * as types from './actionTypes';

const INITIAL_PAGE_SIZE = 10;

const DefaultState = {
  loading: false,
  errorMsg: '',
  purchases: [],
  count: 0,
  total: 0,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
};
export default function adminPurchasesReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.LOAD_ADMIN_PURCHASES_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.LOAD_ADMIN_PURCHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        count: payload.count,
        total: payload.total,
        purchases: payload.rows || null,
      };

    case types.LOAD_ADMIN_PURCHASES_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.LOAD_ADMIN_PURCHASES_PAGINATION_CHANGED:
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };

    default:
      return state;
  }
}
