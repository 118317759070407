import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  count: 0,
  plans: [],
  errorMsg: '',
};
export default function financeReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.GET_TRAINER_SESSION_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_TRAINER_SESSION_PLAN_SUCCESS:
      return {
        ...state,
        plans: payload.data.rows,
        count: payload.data.count,
        loading: false,
      };
    case types.GET_TRAINER_SESSION_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case types.SAVE_TRAINER_SESSION_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_TRAINER_SESSION_PLAN_SUCCESS:
      return {
        ...state,
        plans: payload.data,
        loading: false,
      };
    case types.SAVE_TRAINER_SESSION_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case types.SAVE_TRAINER_SESSION_PLAN_MULTIPLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_TRAINER_SESSION_PLAN_MULTIPLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.SAVE_TRAINER_SESSION_PLAN_MULTIPLE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
}
