import * as types from './actionTypes';

const INITIAL_PAGE_SIZE = 10;

const DefaultState = {
  loading: false,
  errorMsg: '',
  clientsList: [],
  count: 0,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
};
export default function adminClientsListReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.ADMIN_CLIENTS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.ADMIN_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        count: payload.count,
        clientsList: payload.rows || null,
      };

    case types.ADMIN_CLIENTS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.ADMIN_CLIENTS_LIST_PAGINATION_CHANGED:
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };

    case types.ADMIN_CLIENTS_LIST_STATUS_CHANGED:

      return {
        ...state,
        clientsList: state.clientsList.map((client: any) => {
          return client.id !== payload.id ? client : {...client, status: payload.status};
        })
      };
    
    case types.ADMIN_CLIENTS_LIST_DELETED:

      return {
        ...state,
        clientsList: state.clientsList.filter((client: any) => {
          return client.id !== payload.id;
        })
      };

    default:
      return state;
  }
}
