import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  data: {},
  errorMsg: '',
};

export default function userSettingsReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.GET_USER_SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        data: payload || {},
      };

    case types.GET_USER_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || '',
      };

    case types.UPDATE_USER_SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        data: payload,
      };

    case types.UPDATE_USER_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || '',
      };

    case types.DELETE_USER_SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.DELETE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
      };

    case types.DELETE_USER_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || '',
      };


    default:
      return state;
  }
}
