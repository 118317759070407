import {getHistory} from "../modules/store";
import {isNull, has} from 'lodash';


export const redirectVerifyEmail = (currentUser) => {

    if(!isNull(currentUser) && has(currentUser, 'type') ){
        // @ts-ignore
        if(currentUser.type==="client" ){
            getHistory().push('/trainer-calendar');
        }
        if(currentUser.type==="trainer" && currentUser.finishedOnboard){
            getHistory().push('/trainer-calendar');
        }
        if(currentUser.type==="trainer" && !currentUser.finishedOnboard){
            getHistory().push('/onboarding');
        }
        if(currentUser.type==="admin" ){
            getHistory().push('/');
        }
    }
}

export const redirectHome = (currentUser) => {

    if(!isNull(currentUser) && has(currentUser, 'type') ){
        // @ts-ignore
        if(currentUser.type==="client" || currentUser.type==="trainer"){
            getHistory().push('/trainer-calendar');
        }
        if(currentUser.type==="admin" ){
            getHistory().push('/');
        }
    }
}
