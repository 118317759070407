export const SAVE_TRAINER_SESSION_PLAN_LOADING =
  'SAVE_TRAINER_SESSION_PLAN_LOADING';
export const SAVE_TRAINER_SESSION_PLAN_SUCCESS =
  'SAVE_TRAINER_SESSION_PLAN_SUCCESS';
export const SAVE_TRAINER_SESSION_PLAN_FAIL =
  'SAVE_TRAINER_SESSION_PLAN_FAIL';

export const GET_TRAINER_SESSION_PLAN_LOADING =
  'GET_TRAINER_SESSION_PLAN_LOADING';
export const GET_TRAINER_SESSION_PLAN_SUCCESS =
  'GET_TRAINER_SESSION_PLAN_SUCCESS';
export const GET_TRAINER_SESSION_PLAN_FAIL =
  'GET_TRAINER_SESSION_PLAN_FAIL';

export const SAVE_TRAINER_SESSION_PLAN_MULTIPLE_LOADING =
  'SAVE_TRAINER_SESSION_PLAN_MULTIPLE_LOADING';
export const SAVE_TRAINER_SESSION_PLAN_MULTIPLE_SUCCESS =
  'SAVE_TRAINER_SESSION_PLAN_MULTIPLE_SUCCESS';
export const SAVE_TRAINER_SESSION_PLAN_MULTIPLE_FAIL =
  'SAVE_TRAINER_SESSION_PLAN_MULTIPLE_FAIL';
