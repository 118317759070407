export const ADMIN_TRAINERS_LIST_LOADING =
  'LOAD_ADMIN_TRAINERS_LIST_LOADING';
export const ADMIN_TRAINERS_LIST_SUCCESS =
  'LOAD_ADMIN_TRAINERS_LIST_SUCCESS';
export const ADMIN_TRAINERS_LIST_FAIL =
  'LOAD_ADMIN_TRAINERS_LIST_FAIL';
export const ADMIN_TRAINERS_LIST_PAGINATION_CHANGED =
  'ADMIN_TRAINERS_LIST_PAGINATION_CHANGED';
export const ADMIN_TRAINERS_LIST_STATUS_CHANGED =
  'ADMIN_TRAINERS_LIST_STATUS_CHANGED';
export const ADMIN_TRAINERS_LIST_DELETED =
  'ADMIN_TRAINERS_LIST_DELETED';