// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `https://app-training-app.scopicdev.com/api`;
const tenantId = 'd1f31334-4d30-4e50-9816-5a11281ca582';

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'training-app.scopicdev.com',
  protocol: 'https',
};

const externalPagesUrl =
  'https://training-app.scopicdev.com';

const LOGIN_URL =
  'https://app-training-app.scopicdev.com/auth/signin';

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'single';

/**
 * Plan payments configuration.
 */
const isPlanEnabled = true;
const stripePublishableKey =
  'pk_test_51IiTYSHAV37m9YMrkR4n1Cfy0QTib1MUZLJ4oFsBYX0yMJxAgdl9tO7LKktiJW4Saou9Y5RbhiAmmQ7gqU37ai9Z00iQiSBHlY';

export default {
  frontendUrl,
  backendUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey,
  tenantId,
  externalPagesUrl,
  LOGIN_URL,
};
