import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/trainerCalendarSession/importer/trainerCalendarSessionImporterSelectors';
import TrainerCalendarSessionService from 'src/modules/trainerCalendarSession/trainerCalendarSessionService';
import fields from 'src/modules/trainerCalendarSession/importer/trainerCalendarSessionImporterFields';
import { i18n } from 'src/i18n';

const trainerCalendarSessionImporterActions =
  importerActions(
    'TRAINERCALEDARSESSION_IMPORTER',
    selectors,
    TrainerCalendarSessionService.import,
    fields,
    i18n(
      'entities.trainerCaledarSession.importer.fileName',
    ),
  );

export default trainerCalendarSessionImporterActions;
