import { ConnectedRouter } from 'connected-react-router';
import {
  configureStore,
  getHistory,
} from 'src/modules/store';
import React from 'react';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/scss/app.scss';
import 'antd/dist/antd.css';
import ScrollToTop from 'src/view/shared/ScrollToTop';
import Conversations from './view/chat/Conversations';

(window as any).$ = (window as any).jQuery = jQuery;

const store = configureStore();

const App = (props) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={getHistory()}>
        <ScrollToTop />
        <RoutesComponent />
      </ConnectedRouter>
      <Conversations />
    </Provider>
  );
};

export default App;
