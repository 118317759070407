export const ADMIN_CLIENTS_LIST_LOADING =
  'LOAD_ADMIN_CLIENTS_LIST_LOADING';
export const ADMIN_CLIENTS_LIST_SUCCESS =
  'LOAD_ADMIN_CLIENTS_LIST_SUCCESS';
export const ADMIN_CLIENTS_LIST_FAIL =
  'LOAD_ADMIN_CLIENTS_LIST_FAIL';
export const ADMIN_CLIENTS_LIST_PAGINATION_CHANGED =
  'ADMIN_CLIENTS_LIST_PAGINATION_CHANGED';
export const ADMIN_CLIENTS_LIST_STATUS_CHANGED =
  'ADMIN_CLIENTS_LIST_STATUS_CHANGED';
export const ADMIN_CLIENTS_LIST_DELETED =
  'ADMIN_CLIENTS_LIST_DELETED';
