import * as actionTypes from './actionTypes';

const DefaultState = {
  loading: false,
  data: [],
  errorMsg: '',
  count: 0,
};
const categoryListReducer = (
  state = DefaultState,
  { type, payload },
) => {
  switch (type) {
    case actionTypes.CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CATEGORY_SUCCESS:
      return {
        ...state,
        data: payload.rows,
        count: payload.count,
        loading: false,
      };
    case actionTypes.CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};

export default categoryListReducer;
