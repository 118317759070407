/**
 * List of Plans
 */
class Plans {
  static get values() {
    return {
      free: 'free',
      growth: 'growth',
      enterprise: 'enterprise',
    };
  }

  static get planId() {
    return {
      free: 'df5ff5e5-bad3-4b4e-80e2-0f56c94b1be1',
      growth: 'df5ff5e5-bad3-4b4e-80e2-0f56c94b1be2',
      enterprise: 'df5ff5e5-bad3-4b4e-80e2-0f56c94b1be4',
    };
  }
}

export default Plans;
