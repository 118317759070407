import clsx from 'clsx';
import React, { FC, ReactNode, MouseEvent } from 'react';
import config from 'src/config';
import { Menu, Dropdown } from 'antd';
import { MenuItemType } from 'rc-menu/lib/interface';
import MenuLink from './MenuLink';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";

export interface HeaderPropps {
  links: MenuLink[];
  isMenuOpened: boolean;
  setOpenedMenu: (v: boolean) => void;
}

const Header: FC<HeaderPropps> = ({
  links,
  isMenuOpened,
  setOpenedMenu,
}) => {
  const router = useLocation();

  const includeUrl = (itemUrl: string) => {
    if (
      router.pathname.split('/')[1] ===
      itemUrl.split('/')[1]
    ) {
      return 'active-link';
    }

    return '';
  };

  const renderMenu = () => (
    <div className="menu">
      {links.map((item) => {
        if (!item.sub) {
          return (
            <a href={item.url} key={item.name}>
              <div
                className={`link ${includeUrl(item.url)}`}
              >
                {item.name}
              </div>
            </a>
          );
        } else {
          let menuEntries: MenuItemType[] = [];
          item.sub.forEach((sub, i) => {
            let item: ReactNode = (
              <a href={sub.url}>{sub.name}</a>
            );
            menuEntries.push({
              label: item,
              key:
                sub.name.toLowerCase().replace(' ', '') + i,
            });
          });

          const menu = <Menu items={menuEntries} />;

          return (
            <Dropdown key={item.name} overlay={menu}>
              <a onClick={(e) => e.preventDefault()}>
                <div
                  className={`link ${includeUrl(item.url)}`}
                >
                  {item.name}
                  <span className="icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 5.5L7 9.5L11 5.5"
                        stroke="#231F20"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </Dropdown>
          );
        }
      })}
      <a onClick={takeATour} style={{marginRight: 10}}>
        <div className="button button-green">Take A Tour</div>
      </a>
      <a onClick={(e) => redirectToLogin(e)}>
        <div className="button">Sign Up | Log In</div>
      </a>
    </div>
  );

  const takeATour = () => {
    (window as any).takeATour && (window as any).takeATour();
  };

  const redirectToLogin = (e: MouseEvent) => {
    e.preventDefault();
    window.open(config.LOGIN_URL);
  };

  const redirectToRegister = (e: MouseEvent) => {
    e.preventDefault();
    window.open(config.REGISTER_URL);
  };

  return (
    <div className="header">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="box layout">
        <div className="logo">
          <a href="/">
            <img
              src="/images/logo/the-trainory-logo-black.svg"
              alt="Logo"
            />
          </a>
        </div>
        {renderMenu()}
        <div
          className="menu-button"
          onClick={() => setOpenedMenu(!isMenuOpened)}
        >
          {isMenuOpened ? (
            <img
              src="/images/icons/burger-close-icon.svg"
              alt="Close Menu"
            />
          ) : (
            <img
              src="/images/icons/burger-icon.svg"
              alt="Menu"
            />
          )}
        </div>
        <div className="mobile-menu">
          <div
            className={clsx([
              { show: isMenuOpened },
              { hide: !isMenuOpened },
            ])}
          >
            {renderMenu()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
