import React from 'react';
import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';
import { useRouteMatch } from 'react-router-dom';
import LayoutWrapper from 'src/view/layout/styles/LayoutWrapper';
import { useSelector } from 'react-redux';
import layoutSelectors from '../../modules/layout/layoutSelectors';

function Layout(props) {
  const match = useRouteMatch();
  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );
  const isOnboardingPage = () => {
    return match.path.search('onboarding') !== -1;
  };
  return (
    <LayoutWrapper>
      {isOnboardingPage() ? (
        <div className="main">
          <div
            className="content"
            style={{
              backgroundColor: 'rgba(13, 103, 141, 0.8)',
            }}
          >
            {props.children}
          </div>
        </div>
      ) : (
        <>
          <Menu url={match.url} />
          <div className="main">
            <Header />
            <div className="content">{props.children}</div>
          </div>
          {menuVisible && (
            <div className={'footer-bar'}>
              <div className={'horizontal-bar'}/>
            </div>
          )}
        </>
      )}
    </LayoutWrapper>
  );
}

export default Layout;
