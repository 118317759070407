import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/trainerTransfer/importer/trainerTransferImporterSelectors';
import TrainerTransferService from 'src/modules/trainerTransfer/trainerTransferService';
import fields from 'src/modules/trainerTransfer/importer/trainerTransferImporterFields';
import { i18n } from 'src/i18n';

const trainerTransferImporterActions = importerActions(
  'TRAINERTRANSFER_IMPORTER',
  selectors,
  TrainerTransferService.import,
  fields,
  i18n('entities.trainerTransfer.importer.fileName'),
);

export default trainerTransferImporterActions;
