import * as types from './actionTypes';

const INITIAL_PAGE_SIZE = 10;

const DefaultState = {
  loading: false,
  errorMsg: '',
  trainersList: [],
  count: 0,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
};
export default function adminTrainersListReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.ADMIN_TRAINERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.ADMIN_TRAINERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        count: payload.count,
        trainersList: payload.rows || null,
      };

    case types.ADMIN_TRAINERS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    case types.ADMIN_TRAINERS_LIST_PAGINATION_CHANGED:
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };

    case types.ADMIN_TRAINERS_LIST_STATUS_CHANGED:

      return {
        ...state,
        trainersList: state.trainersList.map((trainer: any) => {
          return trainer.id !== payload.id ? trainer : {...trainer, status: payload.status};
        })
      };


    case types.ADMIN_TRAINERS_LIST_DELETED:

      return {
        ...state,
        trainersList: state.trainersList.filter((trainer: any) => {
          return trainer.id !== payload.id;
        })
      };
      
    default:
      return state;
  }
}
