import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.userPreference.fields.id'),
  },
  {
    name: 'type',
    label: i18n('entities.userPreference.fields.type'),
  },
  {
    name: 'email',
    label: i18n('entities.userPreference.fields.email'),
  },
  {
    name: 'name',
    label: i18n('entities.userPreference.fields.name'),
  },
  {
    name: 'phoneNumber',
    label: i18n(
      'entities.userPreference.fields.phoneNumber',
    ),
  },
  {
    name: 'clients',
    label: i18n('entities.userPreference.fields.clients'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'userId',
    label: i18n('entities.userPreference.fields.userId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.userPreference.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.userPreference.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
