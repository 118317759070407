import React from 'react';
import { Route } from 'react-router-dom';
import ExternalLayout from 'src/view/layout/ExternalLayout/ExternalLayout';

function ExternalRoute({
  component: Component,
  currentTenant,
  currentUser,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <ExternalLayout {...props}>
            <Component {...props} />
          </ExternalLayout>
        );
      }}
    />
  );
}

export default ExternalRoute;
