import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.subscription.fields.id'),
  },
  {
    name: 'userId',
    label: i18n('entities.subscription.fields.userId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'subscriptionPlanId',
    label: i18n(
      'entities.subscription.fields.subscriptionPlanId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'startDate',
    label: i18n('entities.subscription.fields.startDate'),
  },
  {
    name: 'endDate',
    label: i18n('entities.subscription.fields.endDate'),
  },
  {
    name: 'sellOnDemandVideo',
    label: i18n(
      'entities.subscription.fields.sellOnDemandVideo',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'trainerTransferId',
    label: i18n(
      'entities.subscription.fields.trainerTransferId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'paymentId',
    label: i18n('entities.subscription.fields.paymentId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.subscription.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.subscription.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
