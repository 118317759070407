import list from 'src/modules/trainerTransfer/list/trainerTransferListReducers';
import form from 'src/modules/trainerTransfer/form/trainerTransferFormReducers';
import view from 'src/modules/trainerTransfer/view/trainerTransferViewReducers';
import destroy from 'src/modules/trainerTransfer/destroy/trainerTransferDestroyReducers';
import importerReducer from 'src/modules/trainerTransfer/importer/trainerTransferImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
