import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import userPreferenceEnumerators from 'src/modules/userPreference/userPreferenceEnumerators';

export default [
  {
    name: 'type',
    label: i18n('entities.userPreference.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.userPreference.fields.type'),
      {
        required: true,
        options: userPreferenceEnumerators.type,
      },
    ),
  },
  {
    name: 'email',
    label: i18n('entities.userPreference.fields.email'),
    schema: schemas.string(
      i18n('entities.userPreference.fields.email'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.userPreference.fields.name'),
    schema: schemas.string(
      i18n('entities.userPreference.fields.name'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'phoneNumber',
    label: i18n(
      'entities.userPreference.fields.phoneNumber',
    ),
    schema: schemas.string(
      i18n('entities.userPreference.fields.phoneNumber'),
      {},
    ),
  },
  {
    name: 'clients',
    label: i18n('entities.userPreference.fields.clients'),
    schema: schemas.relationToMany(
      i18n('entities.userPreference.fields.clients'),
      {},
    ),
  },
  {
    name: 'userId',
    label: i18n('entities.userPreference.fields.userId'),
    schema: schemas.relationToOne(
      i18n('entities.userPreference.fields.userId'),
      {
        required: true,
      },
    ),
  },
];
