import React from 'react';

const SocialMedia = () => (
  <div className="social">
    <div>
      <a
        href={'https://www.facebook.com/TheTrainory'}
        target={'_blank'}
      >
        <img
          src="/images/social/facebook-blue.svg"
          alt="Facebook"
        />
      </a>
    </div>
    <div>
      <a
        href={'https://twitter.com/TheTrainory'}
        target={'_blank'}
      >
        <img
          src="/images/social/twitter-blue.svg"
          alt="Twitter"
        />
      </a>
    </div>
    <div>
      <a
        href={'https://www.instagram.com/thetrainory'}
        target={'_blank'}
      >
        <img
          src="/images/social/instagram-blue.svg"
          alt="Instagram"
        />
      </a>
    </div>
    <div>
      <a
        href={'https://www.linkedin.com/company/the-trainory'}
        target={'_blank'}
      >
        <img
          src="/images/social/linkedin-blue.svg"
          alt="LinkedIn"
        />
      </a>
    </div>
  </div>
);

export default SocialMedia;
