import list from 'src/modules/trainerCalendarSession/list/trainerCalendarSessionListReducers';
import form from 'src/modules/trainerCalendarSession/form/trainerCalendarSessionFormReducers';
import view from 'src/modules/trainerCalendarSession/view/trainerCalendarSessionViewReducers';
import destroy from 'src/modules/trainerCalendarSession/destroy/trainerCalendarSessionDestroyReducers';
import importerReducer from 'src/modules/trainerCalendarSession/importer/trainerCalendarSessionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
