import moment from 'moment';
import TrainerCalendarSessionService from 'src/modules/trainerCalendarSession/trainerCalendarSessionService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'TRAINERCALEDARSESSION_FORM';

export interface ICreateGroupAppointmentValues {
  sessionPlanId: string;
  endTime: Date;
  repeatUntil?: Date;
  repeatOnMonday?: boolean;
  repeatOnTuesday?: boolean;
  repeatOnWednesday?: boolean;
  repeatOnThursday?: boolean;
  repeatOnFriday?: boolean;
  repeatOnSaturday?: boolean;
  repeatOnSunday?: boolean;
}

export interface ICreatePersonalAppointmentValues {
  sessionPlanId: string;
  startTime: Date;
  clientId?: string;
}

export interface ICreateUnavailableValues {
  startTime: Date;
  endTime: Date;
  repeatUntil?: Date;
  repeatOnMonday?: boolean;
  repeatOnTuesday?: boolean;
  repeatOnWednesday?: boolean;
  repeatOnThursday?: boolean;
  repeatOnFriday?: boolean;
  repeatOnSaturday?: boolean;
  repeatOnSunday?: boolean;
}

export interface IDeleteAppointmentValues {
  ids: string[];
  message?: string;
}

const trainerCalendarSessionFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  CREATE_GROUP_APPOINTMENT_STARTED: `${prefix}_CREATE_GROUP_APPOINTMENT_STARTED`,
  CREATE_GROUP_APPOINTMENT_SUCCESS: `${prefix}_CREATE_GROUP_APPOINTMENT_SUCCESS`,
  CREATE_GROUP_APPOINTMENT_ERROR: `${prefix}_CREATE_GROUP_APPOINTMENT_ERROR`,

  CREATE_PERSONAL_APPOINTMENT_STARTED: `${prefix}_CREATE_PERSONAL_APPOINTMENT_STARTED`,
  CREATE_PERSONAL_APPOINTMENT_SUCCESS: `${prefix}_CREATE_PERSONAL_APPOINTMENT_SUCCESS`,
  CREATE_PERSONAL_APPOINTMENT_ERROR: `${prefix}_CREATE_PERSONAL_APPOINTMENT_ERROR`,

  JOIN_GROUP_APPOINTMENT_STARTED: `${prefix}_JOIN_GROUP_APPOINTMENT_STARTED`,
  JOIN_GROUP_APPOINTMENT_SUCCESS: `${prefix}_JOIN_GROUP_APPOINTMENT_SUCCESS`,
  JOIN_GROUP_APPOINTMENT_ERROR: `${prefix}_JOIN_GROUP_APPOINTMENT_ERROR`,

  DELETE_APPOINTMENT_STARTED: `${prefix}_DELETE_APPOINTMENT_STARTED`,
  DELETE_APPOINTMENT_SUCCESS: `${prefix}_DELETE_APPOINTMENT_SUCCESS`,
  DELETE_APPOINTMENT_ERROR: `${prefix}_DELETE_APPOINTMENT_ERROR`,

  CREATE_UNAVAILABLE_STARTED: `${prefix}_CREATE_UNAVAILABLE_STARTED`,
  CREATE_UNAVAILABLE_SUCCESS: `${prefix}_CREATE_UNAVAILABLE_SUCCESS`,
  CREATE_UNAVAILABLE_ERROR: `${prefix}_CREATE_UNAVAILABLE_ERROR`,

  ACCEPT_APPOINTMENT_STARTED: `${prefix}_ACCEPT_APPOINTMENT_STARTED`,
  ACCEPT_APPOINTMENT_SUCCESS: `${prefix}_ACCEPT_APPOINTMENT_SUCCESS`,
  ACCEPT_APPOINTMENT_ERROR: `${prefix}_ACCEPT_APPOINTMENT_ERROR`,

  CANCEL_APPOINTMENT_STARTED: `${prefix}_CANCEL_APPOINTMENT_STARTED`,
  CANCEL_APPOINTMENT_SUCCESS: `${prefix}_CANCEL_APPOINTMENT_SUCCESS`,
  CANCEL_APPOINTMENT_ERROR: `${prefix}_CANCEL_APPOINTMENT_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: trainerCalendarSessionFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TrainerCalendarSessionService.find(
          id,
        );
      }

      dispatch({
        type: trainerCalendarSessionFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerCalendarSessionFormActions.INIT_ERROR,
      });

      getHistory().push('/trainer-calendar-session');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: trainerCalendarSessionFormActions.CREATE_STARTED,
      });

      await TrainerCalendarSessionService.create(values);

      dispatch({
        type: trainerCalendarSessionFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n(
          'entities.trainerCaledarSession.create.success',
        ),
      );

      getHistory().push('/trainer-calendar-session');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerCalendarSessionFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: trainerCalendarSessionFormActions.UPDATE_STARTED,
      });

      await TrainerCalendarSessionService.update(id, values);

      dispatch({
        type: trainerCalendarSessionFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n(
          'entities.trainerCaledarSession.update.success',
        ),
      );

      getHistory().push('/trainer-calendar-session');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerCalendarSessionFormActions.UPDATE_ERROR,
      });
    }
  },

  doJoinGroupAppointment:(sessionID: string, clientId: string | null = null, onSuccess: ((appointment: any) => void) | null = null ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trainerCalendarSessionFormActions.JOIN_GROUP_APPOINTMENT_STARTED,
        });

        const response = await TrainerCalendarSessionService.joinGroupSession(sessionID, clientId);

        dispatch({
          type: trainerCalendarSessionFormActions.JOIN_GROUP_APPOINTMENT_SUCCESS,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: trainerCalendarSessionFormActions.JOIN_GROUP_APPOINTMENT_ERROR,
        });
      }
    },

  doCreateGroupAppointment: (values: ICreateGroupAppointmentValues, onSuccess: (() => void) | null = null) =>
    async (dispatch) => {
      try {
        dispatch({ type: trainerCalendarSessionFormActions.CREATE_GROUP_APPOINTMENT_STARTED });
        await TrainerCalendarSessionService.createGroupAppointment(values);
        dispatch({ type: trainerCalendarSessionFormActions.CREATE_GROUP_APPOINTMENT_SUCCESS });

        onSuccess && onSuccess();
        Message.success('Group Session Successfully created');
      } catch (error) {
        Errors.showMessage(error);
        dispatch({ type: trainerCalendarSessionFormActions.CREATE_GROUP_APPOINTMENT_ERROR });
      }
    },

  doCreateUnavailable:
    (
      values: ICreateUnavailableValues,
      onSuccess: (() => void) | null = null,
    ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trainerCalendarSessionFormActions.CREATE_UNAVAILABLE_STARTED,
        });
        if (moment(values.startTime) > moment(values.endTime)) {
          values = { ...values, endTime:  moment(values.endTime).add(1, 'day').toDate()}
        }
        await TrainerCalendarSessionService.createUnavailable(
          values,
        );

        dispatch({
          type: trainerCalendarSessionFormActions.CREATE_UNAVAILABLE_SUCCESS,
        });
        onSuccess && onSuccess();

        Message.success('Unavailable Successfully created');
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: trainerCalendarSessionFormActions.CREATE_UNAVAILABLE_ERROR,
        });
      }
    },

  doDeleteAppointment:
    (
      values: IDeleteAppointmentValues,
      onSuccess: (() => void) | null = null,
    ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trainerCalendarSessionFormActions.DELETE_APPOINTMENT_STARTED,
        });

        await TrainerCalendarSessionService.deleteAppointment(
          values,
        );

        dispatch({
          type: trainerCalendarSessionFormActions.DELETE_APPOINTMENT_SUCCESS,
        });
        onSuccess && onSuccess();

        Message.success('Entry Successfully deleted');
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: trainerCalendarSessionFormActions.DELETE_APPOINTMENT_ERROR,
        });
      }
    },

  doAcceptAppointment:
    (
      appointmentId: string,
      onSuccess: (() => void) | null = null,
    ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trainerCalendarSessionFormActions.ACCEPT_APPOINTMENT_STARTED,
        });

        await TrainerCalendarSessionService.acceptAppointment(
          appointmentId,
        );

        dispatch({
          type: trainerCalendarSessionFormActions.ACCEPT_APPOINTMENT_SUCCESS,
        });
        onSuccess && onSuccess();

        Message.success(
          'Appointment Successfully Accepted',
        );
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: trainerCalendarSessionFormActions.ACCEPT_APPOINTMENT_ERROR,
        });
      }
    },

  doCancelAppointment: (appointmentId: string, message: string, onSuccess: (() => void) | null = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trainerCalendarSessionFormActions.CANCEL_APPOINTMENT_STARTED,
        });

        await TrainerCalendarSessionService.cancelAppointment(
          appointmentId,
          message,
        );

        dispatch({
          type: trainerCalendarSessionFormActions.CANCEL_APPOINTMENT_SUCCESS,
        });
        onSuccess && onSuccess();

        Message.success(
          'Appointment Successfully Canceled',
        );
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: trainerCalendarSessionFormActions.CANCEL_APPOINTMENT_ERROR,
        });
      }
    },


  doCreatePersonalAppointment: (
    values: ICreatePersonalAppointmentValues,
    onSuccess: ((appointment) => void) | null = null,
  ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trainerCalendarSessionFormActions.CREATE_PERSONAL_APPOINTMENT_STARTED,
        });

        const appointment = await TrainerCalendarSessionService.createPersonalAppointment(
          values,
        );

        dispatch({
          type: trainerCalendarSessionFormActions.CREATE_PERSONAL_APPOINTMENT_SUCCESS,
        });
        onSuccess && onSuccess(appointment);
      } catch (error) {
        Errors.showMessage(error);
        // Errors.handle(error);
        dispatch({
          type: trainerCalendarSessionFormActions.CREATE_PERSONAL_APPOINTMENT_ERROR,
        });
      }
    },
};

export default trainerCalendarSessionFormActions;
