import list from 'src/modules/clientSettings/list/clientSettingsListReducers';
import form from 'src/modules/clientSettings/form/clientSettingsFormReducers';
import view from 'src/modules/clientSettings/view/clientSettingsViewReducers';
import destroy from 'src/modules/clientSettings/destroy/clientSettingsDestroyReducers';
import importerReducer from 'src/modules/clientSettings/importer/clientSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
