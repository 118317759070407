import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.trainerSessionPlan.fields.id'),
  },
  {
    name: 'trainerId',
    label: i18n(
      'entities.trainerSessionPlan.fields.trainerId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'name',
    label: i18n('entities.trainerSessionPlan.fields.name'),
  },
  {
    name: 'sessionTypeId',
    label: i18n(
      'entities.trainerSessionPlan.fields.sessionTypeId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'costPerHour',
    label: i18n(
      'entities.trainerSessionPlan.fields.costPerHour',
    ),
    render: exporterRenders.decimal(2),
  },
  {
    name: 'individualSession',
    label: i18n(
      'entities.trainerSessionPlan.fields.individualSession',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'duration',
    label: i18n(
      'entities.trainerSessionPlan.fields.duration',
    ),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.trainerSessionPlan.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.trainerSessionPlan.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
