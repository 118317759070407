import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userPreference/importer/userPreferenceImporterSelectors';
import UserPreferenceService from 'src/modules/userPreference/userPreferenceService';
import fields from 'src/modules/userPreference/importer/userPreferenceImporterFields';
import { i18n } from 'src/i18n';

const userPreferenceImporterActions = importerActions(
  'USERPREFERENCE_IMPORTER',
  selectors,
  UserPreferenceService.import,
  fields,
  i18n('entities.userPreference.importer.fileName'),
);

export default userPreferenceImporterActions;
