import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import chatActions from '../../modules/chat/chatActions';
import chatSelectors from '../../modules/chat/chatSelectors';
import {Client as ConversationsClient} from '@twilio/conversations';
import {Conversation} from '@twilio/conversations/lib/conversation';
import authSelectors from '../../modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';

const Conversations: React.FC = (_) => {
  const dispatch = useDispatch();
  const chatToken = useSelector(chatSelectors.selectToken);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);

  // fetch the token
  useEffect(() => {
    const emailVerified = new PermissionChecker(currentTenant, currentUser).isEmailVerified;
    if (emailVerified) {
      dispatch(chatActions.doTokenFetch());
    }
  }, [currentUser, currentTenant, dispatch]);

  // create the client for the first time
  useEffect(() => {
    function onMessageAdded(message) {
      dispatch(chatActions.doConversationOnNewMessage(message.conversation.sid));
    }

    function conversationJoined(conversation: Conversation) {
      dispatch(chatActions.doConversationJoin(conversation));
      conversation.getUnreadMessagesCount().then((count) => {
        let unreadCount = count !== null ? count : 0;
        // Check the unread count indexes if unread is zero here
        if (unreadCount === 0 && conversation) {
          if (conversation.lastMessage) {
            if (conversation.lastReadMessageIndex !== null) {
              // calculate if we do have unread messages
              unreadCount = conversation.lastMessage.index as number - conversation.lastReadMessageIndex;
            } else {
              // we have unread messages
              unreadCount = conversation.lastMessage.index as number;
            }
          }
        }

        if (unreadCount > 0) {
          dispatch(chatActions.doConversationSetUnreadMessages(conversation.sid, true));
        }
      })

      conversation.on('messageAdded', onMessageAdded);
    }

    function conversationLeft(conversation: Conversation) {
      conversation.off('messageAdded', onMessageAdded);
      dispatch(chatActions.doConversationLeave(conversation));
    }

    if (chatToken.token) {
      ConversationsClient.create(chatToken.token).then((client) => {
        console.log("Conversations:created");
        (window as any).ConversationsClient = client;
        client.on('conversationJoined', conversationJoined);
        client.on('conversationLeft', conversationLeft);
      });
    }

    return () => {
      const client = (window as any).ConversationsClient;
      if (client) {
        console.log("Conversations:cleanup");
        dispatch(chatActions.doConversationClear());
        client.off('conversationJoined', conversationJoined);
        client.off('conversationLeft',  conversationLeft);
        client.shutdown();
      }
    }
  }, [chatToken.token, dispatch]);

  return null;
};

export default Conversations;
