import list from 'src/modules/trainerFreeSlot/list/trainerFreeSlotListReducers';
import form from 'src/modules/trainerFreeSlot/form/trainerFreeSlotFormReducers';
import view from 'src/modules/trainerFreeSlot/view/trainerFreeSlotViewReducers';
import destroy from 'src/modules/trainerFreeSlot/destroy/trainerFreeSlotDestroyReducers';
import importerReducer from 'src/modules/trainerFreeSlot/importer/trainerFreeSlotImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
