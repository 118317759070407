import actions from 'src/modules/trainer/trainerActions';

const initialData = {
  trainers: null,
  trainersLoading: false,
  trainersErrorMessage: null,

  trainer: null,

  locations: null,
  locationsLoading: false,
  locationsErrorMessage: null,

  languages: null,
  languagesLoading: false,
  languagesErrorMessage: null,

  categories: null,
  categoriesLoading: false,
  categoriesErrorMessage: null,

  video: null,
  videoLoading: false,
  videoErrorMessage: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.VIDEO_FETCH_START) {
    return {
      ...state,
      videoErrorMessage: null,
      videoLoading: true,
    };
  }

  if (type === actions.VIDEO_FETCH_SUCCESS) {
    return {
      ...state,
      video: payload.video || null,
      videoErrorMessage: null,
      videoLoading: false,
    };
  }

  if (type === actions.VIDEO_FETCH_ERROR) {
    return {
      ...state,
      video: null,
      categoriesErrorMessage: payload || null,
      videoLoading: false,
    };
  }

  if (type === actions.CATEGORIES_FETCH_START) {
    return {
      ...state,
      categoriesErrorMessage: null,
      categoriesLoading: true,
    };
  }

  if (type === actions.CATEGORIES_FETCH_SUCCESS) {
    return {
      ...state,
      categories: payload.categories || null,
      categoriesErrorMessage: null,
      categoriesLoading: false,
    };
  }

  if (type === actions.CATEGORIES_FETCH_ERROR) {
    return {
      ...state,
      categories: null,
      categoriesErrorMessage: payload || null,
      categoriesLoading: false,
    };
  }

  if (type === actions.TRAINERS_FETCH_START) {
    return {
      ...state,
      trainersErrorMessage: null,
      trainersLoading: true,
    };
  }

  if (type === actions.TRAINERS_FETCH_SUCCESS) {
    return {
      ...state,
      trainers: payload.trainers || null,
      trainersErrorMessage: null,
      trainersLoading: false,
    };
  }

  if (type === actions.TRAINERS_FETCH_ERROR) {
    return {
      ...state,
      trainers: null,
      trainersErrorMessage: payload || null,
      trainersLoading: false,
    };
  }

  if (type === actions.TRAINERS_FETCH_ONE_START) {
    return {
      ...state,
      trainersErrorMessage: null,
      trainersLoading: true,
    };
  }

  if (type === actions.TRAINERS_FETCH_ONE_SUCCESS) {
    return {
      ...state,
      trainer: payload.trainer || null,
      trainersErrorMessage: null,
      trainersLoading: false,
    };
  }

  if (type === actions.TRAINERS_FETCH_ONE_ERROR) {
    return {
      ...state,
      trainer: null,
      trainersErrorMessage: payload || null,
      trainersLoading: false,
    };
  }

  if (type === actions.TRAINERS_LOCATIONS_FETCH_START) {
    return {
      ...state,
      locationsErrorMessage: null,
      locationsLoading: true,
    };
  }

  if (type === actions.TRAINERS_LOCATIONS_FETCH_SUCCESS) {
    return {
      ...state,
      locations: payload.locations || null,
      locationsErrorMessage: null,
      locationsLoading: false,
    };
  }

  if (type === actions.TRAINERS_LOCATIONS_FETCH_ERROR) {
    return {
      ...state,
      locations: null,
      locationsErrorMessage: payload || null,
      locationsLoading: false,
    };
  }

  if (type === actions.TRAINERS_LANGUAGES_FETCH_START) {
    return {
      ...state,
      languagesErrorMessage: null,
      languagesLoading: true,
    };
  }

  if (type === actions.TRAINERS_LANGUAGES_FETCH_SUCCESS) {
    return {
      ...state,
      languages: payload.languages || null,
      languagesErrorMessage: null,
      languagesLoading: false,
    };
  }

  if (type === actions.TRAINERS_LANGUAGES_FETCH_ERROR) {
    return {
      ...state,
      languages: null,
      languagesErrorMessage: payload || null,
      languagesLoading: false,
    };
  }

  return state;
};
