import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/videoCategory/importer/videoCategoryImporterSelectors';
import VideoCategoryService from 'src/modules/videoCategory/videoCategoryService';
import fields from 'src/modules/videoCategory/importer/videoCategoryImporterFields';
import { i18n } from 'src/i18n';

const videoCategoryImporterActions = importerActions(
  'VIDEOCATEGORY_IMPORTER',
  selectors,
  VideoCategoryService.import,
  fields,
  i18n('entities.videoCategory.importer.fileName'),
);

export default videoCategoryImporterActions;
