import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'userId',
    label: i18n('entities.payment.fields.userId'),
    schema: schemas.relationToOne(
      i18n('entities.payment.fields.userId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'externalId',
    label: i18n('entities.payment.fields.externalId'),
    schema: schemas.string(
      i18n('entities.payment.fields.externalId'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'date',
    label: i18n('entities.payment.fields.date'),
    schema: schemas.date(
      i18n('entities.payment.fields.date'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'amount',
    label: i18n('entities.payment.fields.amount'),
    schema: schemas.decimal(
      i18n('entities.payment.fields.amount'),
      {
        required: true,
        scale: 2,
        min: 0,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.payment.fields.description'),
    schema: schemas.string(
      i18n('entities.payment.fields.description'),
      {
        required: true,
        max: 600,
      },
    ),
  },
];
