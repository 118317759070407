import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/videoLibrary/importer/videoLibraryImporterSelectors';
import VideoLibraryService from 'src/modules/videoLibrary/videoLibraryService';
import fields from 'src/modules/videoLibrary/importer/videoLibraryImporterFields';
import { i18n } from 'src/i18n';

const videoLibraryImporterActions = importerActions(
  'VIDEOLIBRARY_IMPORTER',
  selectors,
  VideoLibraryService.import,
  fields,
  i18n('entities.videoLibrary.importer.fileName'),
);

export default videoLibraryImporterActions;
