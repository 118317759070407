import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  completed: false,
  errorMsg: '',
};
export default function completeOnboardReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.ONBOARDING_COMPLETE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case types.ONBOARDING_COMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        completed: true,
      };

    case types.ONBOARDING_COMPLETE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    default:
      return state;
  }
}
