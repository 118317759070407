import service from 'src/modules/trainer/trainerService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'TRAINER';

const trainerActions = {
  TRAINERS_FETCH_START: `${prefix}_FETCH_START`,
  TRAINERS_FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  TRAINERS_FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  TRAINERS_FETCH_ONE_START: `${prefix}_FETCH_ONE_START`,
  TRAINERS_FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  TRAINERS_FETCH_ONE_ERROR: `${prefix}_FETCH_ONE_ERROR`,

  CATEGORIES_FETCH_START: `${prefix}_CATEGORIES_FETCH_START`,
  CATEGORIES_FETCH_SUCCESS: `${prefix}_CATEGORIES_FETCH_SUCCESS`,
  CATEGORIES_FETCH_ERROR: `${prefix}_CATEGORIES_FETCH_ERROR`,

  VIDEO_FETCH_START: `${prefix}_VIDEO_FETCH_START`,
  VIDEO_FETCH_SUCCESS: `${prefix}_VIDEO_FETCH_SUCCESS`,
  VIDEO_FETCH_ERROR: `${prefix}_VIDEO_FETCH_ERROR`,

  TRAINERS_LOCATIONS_FETCH_START: `${prefix}_LOCATIONS_FETCH_START`,
  TRAINERS_LOCATIONS_FETCH_SUCCESS: `${prefix}_LOCATIONS_FETCH_SUCCESS`,
  TRAINERS_LOCATIONS_FETCH_ERROR: `${prefix}_LOCATIONS_FETCH_ERROR`,

  TRAINERS_LANGUAGES_FETCH_START: `${prefix}_LANGUAGES_FETCH_START`,
  TRAINERS_LANGUAGES_FETCH_SUCCESS: `${prefix}_LANGUAGES_FETCH_SUCCESS`,
  TRAINERS_LANGUAGES_FETCH_ERROR: `${prefix}_LANGUAGES_FETCH_ERROR`,

  doFetchTrainers:
    (params?: {
      page?: number;
      languageId?: string;
      timezoneId?: string;
      categoryIds?: string;
      individualSession?: boolean;
    }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: trainerActions.TRAINERS_FETCH_START,
        });

        let trainers = await service.fetchTrainers(params);

        dispatch({
          type: trainerActions.TRAINERS_FETCH_SUCCESS,
          payload: {
            trainers,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: trainerActions.TRAINERS_FETCH_ERROR,
          payload: error,
        });
      }
    },

  doFetchTrainer: (id: string) => async (dispatch) => {
    try {
      dispatch({
        type: trainerActions.TRAINERS_FETCH_ONE_START,
      });

      let trainer = await service.fetchTrainer(id);

      dispatch({
        type: trainerActions.TRAINERS_FETCH_ONE_SUCCESS,
        payload: {
          trainer,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerActions.TRAINERS_FETCH_ONE_ERROR,
        payload: error,
      });
    }
  },

  doFetchTrainerCategories: () => async (dispatch) => {
    try {
      dispatch({
        type: trainerActions.CATEGORIES_FETCH_START,
      });

      let categories = await service.fetchCategories();

      dispatch({
        type: trainerActions.CATEGORIES_FETCH_SUCCESS,
        payload: {
          categories,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerActions.CATEGORIES_FETCH_ERROR,
        payload: error,
      });
    }
  },

  doFetchTrainerVideo: (id: string) => async (dispatch) => {
    try {
      dispatch({
        type: trainerActions.VIDEO_FETCH_START,
      });

      let video = await service.fetchVideo(id);

      dispatch({
        type: trainerActions.VIDEO_FETCH_SUCCESS,
        payload: {
          video,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerActions.VIDEO_FETCH_ERROR,
        payload: error,
      });
    }
  },

  doFetchLocations: () => async (dispatch) => {
    try {
      dispatch({
        type: trainerActions.TRAINERS_LOCATIONS_FETCH_START,
      });

      let locations = await service.fetchLocations();

      dispatch({
        type: trainerActions.TRAINERS_LOCATIONS_FETCH_SUCCESS,
        payload: {
          locations,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerActions.TRAINERS_LOCATIONS_FETCH_ERROR,
        payload: error,
      });
    }
  },

  doFetchLanguages: () => async (dispatch) => {
    try {
      dispatch({
        type: trainerActions.TRAINERS_LANGUAGES_FETCH_START,
      });

      let languages = await service.fetchLanguages();

      dispatch({
        type: trainerActions.TRAINERS_LANGUAGES_FETCH_SUCCESS,
        payload: {
          languages,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerActions.TRAINERS_LANGUAGES_FETCH_ERROR,
        payload: error,
      });
    }
  },
};

export default trainerActions;
