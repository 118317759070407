import TrainerCalendarSessionService from 'src/modules/trainerCalendarSession/trainerCalendarSessionService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'TRAINERCALEDARSESSION_VIEW';

const trainerCalendarSessionViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: trainerCalendarSessionViewActions.FIND_STARTED,
      });

      const record =
        await TrainerCalendarSessionService.find(id);

      dispatch({
        type: trainerCalendarSessionViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: trainerCalendarSessionViewActions.FIND_ERROR,
      });

      getHistory().push('/trainer-calendar-session');
    }
  },
};

export default trainerCalendarSessionViewActions;
