import * as types from './actionTypes';

const DefaultState = {
  loading: false,
  total: '',
  totalAppFee: '',
  totalStripeFee: '',
};

export default function myBalanceReducer(
  state = DefaultState,
  { type, payload },
) {
  switch (type) {
    case types.FINANCE_MY_BALANCE_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case types.FINANCE_MY_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        ...payload.balance
      };

    case types.FINANCE_MY_BALANCE_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: payload || null,
      };

    default:
      return state;
  }
}
